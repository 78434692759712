.profile-view-private {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Background overlay */
  z-index: 1000; /* Ensure the modal is above other content */
}

.profile-view-private .div {
  background-color: var(--collection-1-changing-variables-background-of-screen);
  width: 1200px; /* Adjusted width */
  height: 850px; /* Adjusted height */
  position: relative;
  border-radius: 16px; /* Increased border-radius for rounded corners */
  overflow: hidden; /* Ensure the content does not overflow */
}

.profile-view-private .overlap {
  position: absolute;
  width: 1200px;
  height: 320px; /* Adjusted height for the header */
  top: 0;
  left: 0;
}

.profile-view-private .header-BG {
  height: 100%; /* Adjusted to fill the header container */
  width: 100%;
  top: 0;
  position: absolute;
}

.profile-view-private .header-underline {
  height: 1px;
  top: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
  left: 0;
}

.profile-view-private .logo-slogan {
  position: absolute;
  width: 250px; /* Adjusted width */
  height: 100px; /* Adjusted height */
  top: 60px; /* Adjusted top position */
  left: 50px;
}

.profile-view-private .logo-main {
  position: absolute;
  width: 230px; /* Adjusted size */
  height: 75px; /* Adjusted size */
  top: 0;
  left: 0;
}

.profile-view-private .encircle-the-world {
  position: absolute;
  width: 150px; /* Adjusted width */
  height: 15px;
  top: 80px;
  left: 8px;
}

.profile-view-private .change-profile {
  position: absolute;
  width: 150px; /* Adjusted profile picture size */
  height: 150px; /* Adjusted profile picture size */
  top: 200px; /* Adjusted position */
  left: 520px;
  background-size: cover;
  background-position: 50% 50%;
}

.profile-view-private .frame {
  display: inline-flex;
  flex-direction: column;
  height: 280px; /* Adjusted frame height */
  align-items: flex-end;
  gap: 20px; /* Reduced gap between elements */
  position: absolute;
  top: 380px;
  left: 650px;
}

.profile-view-private .frame-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Reduced gap */
  padding: 8px; /* Adjusted padding */
  position: relative;
  flex: 0 0 auto;
  border-radius: 12px; /* Adjusted radius */
  border: 0.5px solid;
  border-color: var(--collection-1-changing-variables-stroke-white);
}

.profile-view-private .text-wrapper {
  position: relative;
  font-family: var(--h4-bold-font-family);
  font-weight: var(--h4-bold-font-weight);
  color: var(--collection-1-changing-variables-text-white);
  font-size: 20px; /* Adjusted font size */
  letter-spacing: 0.5px; /* Adjusted letter spacing */
  line-height: 1.5; /* Adjusted line height */
  direction: rtl;
}

.profile-view-private .frame-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  gap: 8px; /* Reduced gap */
  padding: 14px; /* Reduced padding */
  position: relative;
  flex: 1;
  flex-grow: 1;
  border-radius: 12px; /* Adjusted border radius */
  overflow: hidden;
  border: 0.5px solid;
  border-color: var(--collection-1-changing-variables-stroke-white);
}

.profile-view-private .frame-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.profile-view-private .frame-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.profile-view-private .img {
  position: relative;
  width: 20px; /* Adjusted image size */
  height: 20px; /* Adjusted image size */
}

.profile-view-private .text-wrapper-2 {
  position: relative;
  font-family: var(--body-list-body-20-r-font-family);
  font-weight: var(--body-list-body-20-r-font-weight);
  color: var(--collection-1-changing-variables-text-white);
  font-size: 16px; /* Adjusted font size */
  letter-spacing: 0.5px;
  line-height: 1.5;
  direction: rtl;
}

.profile-view-private .text-wrapper-3 {
  position: relative;
  font-family: var(--body-list-body-20-b-font-family);
  font-weight: var(--body-list-body-20-b-font-weight);
  color: var(--collection-1-changing-variables-text-white);
  font-size: 16px; /* Adjusted font size */
  letter-spacing: 0.5px;
  line-height: 1.5;
  direction: rtl;
}

.profile-view-private .text-wrapper-4 {
  position: relative;
  font-family: var(--body-list-body-20-r-font-family);
  font-weight: var(--body-list-body-20-r-font-weight);
  color: var(--collection-1-changing-variables-text-white);
  font-size: 16px; /* Adjusted font size */
  text-align: right;
  letter-spacing: 0.5px;
  line-height: 1.5;
}

.profile-view-private .frame-5 {
  display: inline-flex;
  align-items: center;
  gap: 24px; /* Reduced gap */
  position: relative;
  flex: 0 0 auto;
}

.profile-view-private .frame-6 {
  display: flex;
  flex-direction: column;
  width: 500px; /* Adjusted width */
  height: 150px; /* Adjusted height */
  align-items: flex-end;
  gap: 30px; /* Reduced gap */
  position: absolute;
  top: 710px;
  left: 650px;
}

.profile-view-private .frame-7 {
  width: 500px; /* Adjusted width */
  gap: 30px; /* Reduced gap */
  position: absolute;
  top: 710px;
  left: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.profile-view-private .frame-8 {
  gap: 8px; /* Reduced gap */
  padding: 12px; /* Reduced padding */
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  border-radius: 12px; /* Adjusted border radius */
  overflow: hidden;
  border: 0.5px solid;
  border-color: var(--collection-1-changing-variables-stroke-white);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.profile-view-private .frame-9 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px; /* Reduced gap */
  position: relative;
  align-self: stretch;
  width: 100%;
}

.profile-view-private .social-icons-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 8px; /* Reduced gap */
  padding: 6px; /* Reduced padding */
  position: relative;
  flex: 0 0 auto;
  margin-top: -1.00px;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--collection-1-changing-variables-stroke-button);
}

.profile-view-private .text-wrapper-5 {
  position: relative;
  width: fit-content;
  font-family: var(--body-list-body-20-r-font-family);
  font-weight: var(--body-list-body-20-r-font-weight);
  color: var(--collection-1-changing-variables-text-white);
  font-size: 16px; /* Adjusted font size */
  text-align: right;
  letter-spacing: 0.5px;
  line-height: 1.5;
}

.profile-view-private .text-wrapper-6 {
  position: relative;
  width: fit-content;
  font-family: var(--body-list-body-20-b-font-family);
  font-weight: var(--body-list-body-20-b-font-weight);
  color: var(--collection-1-changing-variables-text-white);
  font-size: 16px; /* Adjusted font size */
  letter-spacing: 0.5px;
  line-height: 1.5;
  direction: rtl;
}

:root {
  --dark: rgba(18, 18, 18, 1);
  --grey: rgba(179, 179, 179, 1);
  --primary: rgba(74, 27, 79, 1);
  --secondary: rgba(79, 217, 252, 1);
  --white: rgba(255, 255, 255, 1);
  --youtuber: rgba(255, 0, 0, 1);
  --yellow-imgn: rgba(249, 248, 113, 1);
  --faded-bars: rgba(179, 179, 179, 0.5);
  --secondary-alternative: rgba(178, 114, 197, 1);
  --h3-bold-font-family: "Poppins", Helvetica;
  --h3-bold-font-weight: 700;
  --h3-bold-font-size: 32px;
  --h3-bold-letter-spacing: 0px;
  --h3-bold-line-height: normal;
  --h3-bold-font-style: normal;
  --h4-bold-font-family: "Poppins", Helvetica;
  --h4-bold-font-weight: 700;
  --h4-bold-font-size: 24px;
  --h4-bold-letter-spacing: 0px;
  --h4-bold-line-height: normal;
  --h4-bold-font-style: normal;
  --h5-bold-BT-font-family: "Poppins", Helvetica;
  --h5-bold-BT-font-weight: 700;
  --h5-bold-BT-font-size: 18px;
  --h5-bold-BT-letter-spacing: 0px;
  --h5-bold-BT-line-height: normal;
  --h5-bold-BT-font-style: normal;
  --article-card-title-font-family: "Poppins", Helvetica;
  --article-card-title-font-weight: 700;
  --article-card-title-font-size: 12px;
  --article-card-title-letter-spacing: 0px;
  --article-card-title-line-height: normal;
  --article-card-title-font-style: normal;
  --character-title-taj-32-b-font-family: "Tajawal", Helvetica;
  --character-title-taj-32-b-font-weight: 700;
  --character-title-taj-32-b-font-size: 32px;
  --character-title-taj-32-b-letter-spacing: 0px;
  --character-title-taj-32-b-line-height: normal;
  --character-title-taj-32-b-font-style: normal;
  --terms-and-conditions-pop-10-r-font-family: "Poppins", Helvetica;
  --terms-and-conditions-pop-10-r-font-weight: 400;
  --terms-and-conditions-pop-10-r-font-size: 10px;
  --terms-and-conditions-pop-10-r-letter-spacing: 0px;
  --terms-and-conditions-pop-10-r-line-height: normal;
  --terms-and-conditions-pop-10-r-font-style: normal;
  --search-bars-CTA-popp-r-10-font-family: "Poppins", Helvetica;
  --search-bars-CTA-popp-r-10-font-weight: 400;
  --search-bars-CTA-popp-r-10-font-size: 10px;
  --search-bars-CTA-popp-r-10-letter-spacing: 0px;
  --search-bars-CTA-popp-r-10-line-height: normal;
  --search-bars-CTA-popp-r-10-font-style: normal;
  --articles-title-tajwal-32-b-font-family: "Tajawal", Helvetica;
  --articles-title-tajwal-32-b-font-weight: 700;
  --articles-title-tajwal-32-b-font-size: 32px;
  --articles-title-tajwal-32-b-letter-spacing: 0px;
  --articles-title-tajwal-32-b-line-height: normal;
  --articles-title-tajwal-32-b-font-style: normal;
  --search-bar-menu-taj-14-light-font-family: "Tajawal", Helvetica;
  --search-bar-menu-taj-14-light-font-weight: 300;
  --search-bar-menu-taj-14-light-font-size: 12px;
  --search-bar-menu-taj-14-light-letter-spacing: 0px;
  --search-bar-menu-taj-14-light-line-height: 150%;
  --search-bar-menu-taj-14-light-font-style: normal;
  --categories-name-menu-taj-16-bold-font-family: "Tajawal", Helvetica;
  --categories-name-menu-taj-16-bold-font-weight: 700;
  --categories-name-menu-taj-16-bold-font-size: 16px;
  --categories-name-menu-taj-16-bold-letter-spacing: 0px;
  --categories-name-menu-taj-16-bold-line-height: 150%;
  --categories-name-menu-taj-16-bold-font-style: normal;
  --nav-bar-footer-cat-tajwal-24-medium-font-family: "Tajawal", Helvetica;
  --nav-bar-footer-cat-tajwal-24-medium-font-weight: 500;
  --nav-bar-footer-cat-tajwal-24-medium-font-size: 24px;
  --nav-bar-footer-cat-tajwal-24-medium-letter-spacing: 0px;
  --nav-bar-footer-cat-tajwal-24-medium-line-height: 150%;
  --nav-bar-footer-cat-tajwal-24-medium-font-style: normal;
  --date-of-puplish-card-poppins-10-regular-font-family: "Poppins", Helvetica;
  --date-of-puplish-card-poppins-10-regular-font-weight: 400;
  --date-of-puplish-card-poppins-10-regular-font-size: 10px;
  --date-of-puplish-card-poppins-10-regular-letter-spacing: 0px;
  --date-of-puplish-card-poppins-10-regular-line-height: normal;
  --date-of-puplish-card-poppins-10-regular-font-style: normal;
  --status-label-b-n-a-poppins-8-bold-font-family: "Poppins", Helvetica;
  --status-label-b-n-a-poppins-8-bold-font-weight: 700;
  --status-label-b-n-a-poppins-8-bold-font-size: 8px;
  --status-label-b-n-a-poppins-8-bold-letter-spacing: 0px;
  --status-label-b-n-a-poppins-8-bold-line-height: normal;
  --status-label-b-n-a-poppins-8-bold-font-style: normal;
  --b-n-a-card-description-tajwal-10-medium-font-family: "Tajawal", Helvetica;
  --b-n-a-card-description-tajwal-10-medium-font-weight: 500;
  --b-n-a-card-description-tajwal-10-medium-font-size: 10px;
  --b-n-a-card-description-tajwal-10-medium-letter-spacing: 0px;
  --b-n-a-card-description-tajwal-10-medium-line-height: 150%;
  --b-n-a-card-description-tajwal-10-medium-font-style: normal;
  --FAQ-tag-label-tajwal-10-medium-font-family: "Tajawal", Helvetica;
  --FAQ-tag-label-tajwal-10-medium-font-weight: 500;
  --FAQ-tag-label-tajwal-10-medium-font-size: 10px;
  --FAQ-tag-label-tajwal-10-medium-letter-spacing: 0px;
  --FAQ-tag-label-tajwal-10-medium-line-height: 150%;
  --FAQ-tag-label-tajwal-10-medium-font-style: normal;
  --staff-member-name-tajwal-24-bold-font-family: "Tajawal", Helvetica;
  --staff-member-name-tajwal-24-bold-font-weight: 700;
  --staff-member-name-tajwal-24-bold-font-size: 24px;
  --staff-member-name-tajwal-24-bold-letter-spacing: 0px;
  --staff-member-name-tajwal-24-bold-line-height: normal;
  --staff-member-name-tajwal-24-bold-font-style: normal;
  --profile-card-taj-20-medium-font-family: "Tajawal", Helvetica;
  --profile-card-taj-20-medium-font-weight: 500;
  --profile-card-taj-20-medium-font-size: 20px;
  --profile-card-taj-20-medium-letter-spacing: 0px;
  --profile-card-taj-20-medium-line-height: normal;
  --profile-card-taj-20-medium-font-style: normal;
  --profile-nickname-taj-14-light-font-family: "Tajawal", Helvetica;
  --profile-nickname-taj-14-light-font-weight: 300;
  --profile-nickname-taj-14-light-font-size: 14px;
  --profile-nickname-taj-14-light-letter-spacing: 0px;
  --profile-nickname-taj-14-light-line-height: normal;
  --profile-nickname-taj-14-light-font-style: normal;
  --categories-name-taj-16-bold-font-family: "Tajawal", Helvetica;
  --categories-name-taj-16-bold-font-weight: 700;
  --categories-name-taj-16-bold-font-size: 16px;
  --categories-name-taj-16-bold-letter-spacing: 0px;
  --categories-name-taj-16-bold-line-height: 150%;
  --categories-name-taj-16-bold-font-style: normal;
  --suggested-video-TAJ-8-med-font-family: "Tajawal", Helvetica;
  --suggested-video-TAJ-8-med-font-weight: 500;
  --suggested-video-TAJ-8-med-font-size: 8px;
  --suggested-video-TAJ-8-med-letter-spacing: 0px;
  --suggested-video-TAJ-8-med-line-height: 150%;
  --suggested-video-TAJ-8-med-font-style: normal;
  --rating-circle-figtree-20-bold-font-family: "Figtree", Helvetica;
  --rating-circle-figtree-20-bold-font-weight: 700;
  --rating-circle-figtree-20-bold-font-size: 20px;
  --rating-circle-figtree-20-bold-letter-spacing: -0.4px;
  --rating-circle-figtree-20-bold-line-height: 22px;
  --rating-circle-figtree-20-bold-font-style: normal;
  --h-s-poppins-h1-font-family: "Poppins", Helvetica;
  --h-s-poppins-h1-font-weight: 700;
  --h-s-poppins-h1-font-size: 48px;
  --h-s-poppins-h1-letter-spacing: 0px;
  --h-s-poppins-h1-line-height: normal;
  --h-s-poppins-h1-font-style: normal;
  --h-s-poppins-h2-font-family: "Poppins", Helvetica;
  --h-s-poppins-h2-font-weight: 400;
  --h-s-poppins-h2-font-size: 40px;
  --h-s-poppins-h2-letter-spacing: 0px;
  --h-s-poppins-h2-line-height: normal;
  --h-s-poppins-h2-font-style: normal;
  --h-s-poppins-h3-font-family: "Poppins", Helvetica;
  --h-s-poppins-h3-font-weight: 700;
  --h-s-poppins-h3-font-size: 32px;
  --h-s-poppins-h3-letter-spacing: 0px;
  --h-s-poppins-h3-line-height: normal;
  --h-s-poppins-h3-font-style: normal;
  --h-s-poppins-h4-font-family: "Poppins", Helvetica;
  --h-s-poppins-h4-font-weight: 400;
  --h-s-poppins-h4-font-size: 24px;
  --h-s-poppins-h4-letter-spacing: 0px;
  --h-s-poppins-h4-line-height: normal;
  --h-s-poppins-h4-font-style: normal;
  --others-tajwal-paragraph-20-font-family: "Tajawal", Helvetica;
  --others-tajwal-paragraph-20-font-weight: 400;
  --others-tajwal-paragraph-20-font-size: 20px;
  --others-tajwal-paragraph-20-letter-spacing: 0px;
  --others-tajwal-paragraph-20-line-height: 150%;
  --others-tajwal-paragraph-20-font-style: normal;
  --others-tajwal-paragraph-18-r-font-family: "Tajawal", Helvetica;
  --others-tajwal-paragraph-18-r-font-weight: 400;
  --others-tajwal-paragraph-18-r-font-size: 18px;
  --others-tajwal-paragraph-18-r-letter-spacing: 0px;
  --others-tajwal-paragraph-18-r-line-height: 150%;
  --others-tajwal-paragraph-18-r-font-style: normal;
  --others-tajwal-caption-14-font-family: "Tajawal", Helvetica;
  --others-tajwal-caption-14-font-weight: 500;
  --others-tajwal-caption-14-font-size: 14px;
  --others-tajwal-caption-14-letter-spacing: 0px;
  --others-tajwal-caption-14-line-height: 150%;
  --others-tajwal-caption-14-font-style: normal;
  --others-tajwal-caption-12-font-family: "Tajawal", Helvetica;
  --others-tajwal-caption-12-font-weight: 500;
  --others-tajwal-caption-12-font-size: 12px;
  --others-tajwal-caption-12-letter-spacing: 0px;
  --others-tajwal-caption-12-line-height: 150%;
  --others-tajwal-caption-12-font-style: normal;
  --others-tajwal-button-14-font-family: "Tajawal", Helvetica;
  --others-tajwal-button-14-font-weight: 700;
  --others-tajwal-button-14-font-size: 14px;
  --others-tajwal-button-14-letter-spacing: 0px;
  --others-tajwal-button-14-line-height: normal;
  --others-tajwal-button-14-font-style: normal;
  --others-tajwal-caption-12-bold-font-family: "Tajawal", Helvetica;
  --others-tajwal-caption-12-bold-font-weight: 700;
  --others-tajwal-caption-12-bold-font-size: 12px;
  --others-tajwal-caption-12-bold-letter-spacing: 0px;
  --others-tajwal-caption-12-bold-line-height: normal;
  --others-tajwal-caption-12-bold-font-style: normal;
  --body-list-body-20-b-font-family: "Poppins", Helvetica;
  --body-list-body-20-b-font-weight: 700;
  --body-list-body-20-b-font-size: 20px;
  --body-list-body-20-b-letter-spacing: 0px;
  --body-list-body-20-b-line-height: normal;
  --body-list-body-20-b-font-style: normal;
  --body-list-body-20-r-font-family: "Poppins", Helvetica;
  --body-list-body-20-r-font-weight: 400;
  --body-list-body-20-r-font-size: 20px;
  --body-list-body-20-r-letter-spacing: 0px;
  --body-list-body-20-r-line-height: normal;
  --body-list-body-20-r-font-style: normal;
  --body-list-body-18-b-font-family: "Poppins", Helvetica;
  --body-list-body-18-b-font-weight: 700;
  --body-list-body-18-b-font-size: 18px;
  --body-list-body-18-b-letter-spacing: 0px;
  --body-list-body-18-b-line-height: normal;
  --body-list-body-18-b-font-style: normal;
  --body-list-body-18-r-font-family: "Poppins", Helvetica;
  --body-list-body-18-r-font-weight: 400;
  --body-list-body-18-r-font-size: 18px;
  --body-list-body-18-r-letter-spacing: 0px;
  --body-list-body-18-r-line-height: normal;
  --body-list-body-18-r-font-style: normal;
  --body-list-body-16-b-font-family: "Poppins", Helvetica;
  --body-list-body-16-b-font-weight: 700;
  --body-list-body-16-b-font-size: 16px;
  --body-list-body-16-b-letter-spacing: 0px;
  --body-list-body-16-b-line-height: normal;
  --body-list-body-16-b-font-style: normal;
  --body-list-body-16-r-font-family: "Poppins", Helvetica;
  --body-list-body-16-r-font-weight: 400;
  --body-list-body-16-r-font-size: 16px;
  --body-list-body-16-r-letter-spacing: 0px;
  --body-list-body-16-r-line-height: normal;
  --body-list-body-16-r-font-style: normal;
  --body-list-body-14-r-font-family: "Poppins", Helvetica;
  --body-list-body-14-r-font-weight: 400;
  --body-list-body-14-r-font-size: 14px;
  --body-list-body-14-r-letter-spacing: 0px;
  --body-list-body-14-r-line-height: normal;
  --body-list-body-14-r-font-style: normal;
  --collection-1-changing-variables-text-white: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-stroke-white: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-stroke-button: rgba(18, 18, 18, 1);
  --collection-1-changing-variables-background-of-screen: rgba(18, 18, 18, 1);
  --collection-1-changing-variables-faded-bars: rgba(179, 179, 179, 0.5);
  --collection-1-changing-variables-toggles-points: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-icons: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-labels: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-labels-text: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-rating-circle: rgba(79, 217, 252, 1);
  --collection-1-changing-variables-buttons: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-button-text: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-buttons-click-select: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-button-text-click-select: rgba(
      255,
      255,
      255,
      1
  );
  --collection-1-changing-variables-placeholders-and-faded-text: rgba(
      179,
      179,
      179,
      1
  );
  --collection-1-fixed-colours-text-white-fixed: rgba(255, 255, 255, 1);
  --collection-1-fixed-colours-stroke-white-fixed: rgba(255, 255, 255, 1);
  --collection-1-fixed-colours-background-of-screen-fixed: rgba(18, 18, 18, 1);
  --collection-1-fixed-colours-faded-bars-fixed: rgba(179, 179, 179, 0.5);
  --collection-1-fixed-colours-toggles-points-fixed: rgba(255, 255, 255, 1);
  --collection-1-fixed-colours-icons-fixed: rgba(255, 255, 255, 1);
  --collection-1-fixed-colours-buttons-fixed: rgba(255, 255, 255, 1);
  --collection-1-fixed-colours-button-text-fixed: rgba(74, 27, 79, 1);
  --collection-1-fixed-colours-placeholders-and-faded-text-fixed: rgba(
      179,
      179,
      179,
      1
  );
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-collection-1-mode="dark">
      <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-collection-1-mode="dark"] {
  --collection-1-changing-variables-text-white: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-stroke-white: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-stroke-button: rgba(18, 18, 18, 1);
  --collection-1-changing-variables-background-of-screen: rgba(18, 18, 18, 1);
  --collection-1-changing-variables-faded-bars: rgba(179, 179, 179, 0.5);
  --collection-1-changing-variables-toggles-points: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-icons: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-labels: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-labels-text: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-rating-circle: rgba(79, 217, 252, 1);
  --collection-1-changing-variables-buttons: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-button-text: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-buttons-click-select: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-button-text-click-select: rgba(
      255,
      255,
      255,
      1
  );
  --collection-1-changing-variables-placeholders-and-faded-text: rgba(
      179,
      179,
      179,
      1
  );
}

[data-collection-1-mode="light"] {
  --collection-1-changing-variables-text-white: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-stroke-white: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-stroke-button: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-background-of-screen: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-faded-bars: rgba(179, 179, 179, 0.8);
  --collection-1-changing-variables-toggles-points: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-icons: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-labels: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-labels-text: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-rating-circle: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-buttons: rgba(74, 27, 79, 1);
  --collection-1-changing-variables-button-text: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-buttons-click-select: rgba(255, 255, 255, 1);
  --collection-1-changing-variables-button-text-click-select: rgba(
      74,
      27,
      79,
      1
  );
  --collection-1-changing-variables-placeholders-and-faded-text: rgba(
      73,
      74,
      73,
      1
  );
}
