.articles-filters-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #fff;
  font-family: "Tajawal", sans-serif;
}

/* 🔹 Section Titles */
.filter-title {
  align-self: stretch;
  text-align: right;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

/* 🔹 Static and Dynamic Filters */
.fixed-filters,
.dynamic-filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

/* 🔹 Individual Filter Tag */
.filter-tag-container {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 10px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  white-space: nowrap;
}

.filter-tag-container .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.filter-tag-container .icon-container svg {
  width: 100%;
  height: 100%;
  fill: #ff4d4d;
  transition: 0.2s ease-in-out;
}

.filter-tag-container .icon-container:hover svg {
  fill: #d32f2f;
}

/* 🔹 Filter Button */
.filter-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(179, 179, 179, 0.5);
  padding: 10.5px 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  border: none;
  cursor: pointer;
  flex: 1 0 0;
  transition: background 0.3s ease;
  color: var(--Changing-Variables-Placeholders-and-Faded-Text, #B3B3B3);
  text-align: center;
  font-family: Tajawal;
}

.filter-button:hover {
  background-color: rgba(179, 179, 179, 0.7);
}

/* 🔹 Dynamic Filter Tag Style */
.filter-tag {
  display: flex;
  width: 238px;
  padding: 10.5px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(179, 179, 179, 0.5);
  color: var(--Changing-Variables-Placeholders-and-Faded-Text, #B3B3B3);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.filter-tag:hover {
  background-color: rgba(179, 179, 179, 0.7);
}

/* 🔹 Filter Input Section */
.add-filter-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.filter-input {
  flex: 1;
  min-width: 220px;
  color: var(--Changing-Variables-Placeholders-and-Faded-Text, #B3B3B3);
  font-family: Tajawal, sans-serif;
  font-size: 18px;
  padding: 10.5px 16px;
  border-radius: 8px;
  border: 2px solid rgba(179, 179, 179, 0.5);
  background: transparent;
  text-align: right;
  appearance: none;
  transition: border 0.3s ease;
}

.filter-input::placeholder {
  color: #B3B3B3;
  opacity: 1;
  font-size: 16px;
}

.filter-input:hover,
.filter-input:focus {
  border: 2px solid var(--Changing-Variables-Buttons);
}

/* 🔹 Add Filter Section */
.add-filter-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

/* 🔹 Add Filter Button */
.add-filter-button {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 250px;
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid var(--Changing-Variables-Stroke-Button, #121212);
  background: var(--Changing-Variables-Buttons, #FFF);
  color: var(--Changing-Variables-Button-Text, #4A1B4F);
  font-family: Tajawal;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.add-filter-button:hover {
  background: var(--Changing-Variables-Button-Text);
  color: var(--Changing-Variables-Text-White);
}

.add-filter-button:hover .filter-icon {
  fill: black;
  color: black;
}

/* 🔹 Info Text */
.info-text {
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  margin-top: 10px;
}

/* ✅ Responsive Design */
@media screen and (max-width: 1235px) {
  .filter-button {
    font-size: 16px;
    padding: 8px 12px;
  }

  .filter-tag {
    width: 100%;
  }

  .add-filter-button {
    font-size: 16px;
    width: 100%;
    padding: 10px 16px;
  }

  .add-filter-input {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-input {
    width: 100%;
    font-size: 16px;
  }

  .add-filter-section {
    flex-direction: column;
    align-items: stretch;
  }
}
