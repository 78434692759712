
.sign-in-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Prevent scrolling */
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    position: relative; /* Keep position in control */
  }
  
  /* Container */
  .sign-in-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1440px; /* Ensures it does not exceed the page */
    max-height: 810px; /* Restrict the size */
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
  }
  
  /* Left Side */
  .sign-in-left {
    width: 512px;
    height: 576px;
    flex-shrink: 0;
    border-radius: 16px 0px 0px 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.5%, #000 100%),
      url("/public/leftside.png") no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25); /* Slight shadow for depth */
    z-index: 2; /* Ensure content stays above the SVG */
  }
  
  .sign-in-left h1 {
    font-family: Tajawal;
    font-size: 48px;
    font-weight: 700;
    color: #fff;
    text-align: right;
    margin-bottom: 10px;
  }
  
  .sign-in-left p {
    font-family: Tajawal;
    font-size: 18px;
    font-weight: 400;
    color: #b3b3b3;
    line-height: 27px;
    text-align: right;
  }
  
  /* Right Side */
  .sign-in-right {
    width: 512px;
    height: 576px;
    flex-shrink: 0;
    border-radius: 0px 16px 16px 0px;
    background: var(--Primary, #4A1B4F);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25); /* Slight shadow for depth */
    z-index: 2; /* Ensure content stays above the SVG */
  }
  
  .sign-in-logo {
    width: 150px;
    margin-bottom: 30px;
  }
  
  .input-field {
    width: 90%;
    max-width: 400px;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: none;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  
  .input-field::placeholder {
    color: #ccc;
  }
  
  .primary-button-signin {
    background-color: #4fd9fc;
    color: #4a1b4f;
    width: 90%;
    max-width: 400px;
    padding: 12px;
    border-radius: 8px;
    border: none;
    margin-top: 10px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .primary-button-signin:hover {
    background-color: #3cb9d3;
  }
  
  .forgot-password {
    margin-top: 10px;
    color: #b3b3b3;
    font-size: 14px;
    text-decoration: none;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  
  /* Divider */
  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto; /* Centered divider */
    width: 148px; /* Fixed width */
    height: 1px;
  }
  
  .divider::before,
  .divider::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #b3b3b3; /* Light grey lines */
  }
  
  .divider span {
    font-size: 14px;
    color: #fff;
    margin: 0 10px; /* Space between the lines and text */
  }
  
  /* Social Buttons */
  .social-buttons {
    display: flex;
    gap: 20px;
  }
  
  .social-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #fff;
    color: #4a1b4f;
    border-radius: 50%;
    font-size: 18px;
    transition: transform 0.3s ease;
  }
  
  .social-button:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Register Link */
  .register-link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; /* Ensure text and link stay in a row */
    gap: 5px; /* Small space between text and link */
    margin-top: 30px;
    color: #b3b3b3;
    
  }
  
  .register-now {
    color: #4fd9fc;
    text-decoration: none;
    font-weight: bold;
  }
  
  .register-now:hover {
    text-decoration: underline;
  }
  
  /* SVG Positioned Behind Content */
  .background-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    z-index: 1; /* Behind the content */
    opacity: 1;
    pointer-events: none; /* Prevent interaction with the SVG */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sign-in-container {
      flex-direction: column;
      height: auto;
      border-radius: 0;
      padding: 20px;
    }
  
    .sign-in-left,
    .sign-in-right {
      width: 100%;
      height: auto;
      border-radius: 0;
      padding: 20px;
    }
  
    .input-field,
    .primary-button {
      width: 100%;
      max-width: 100%;
    }
  }
  