/* 🌟 Content Area Layout */
/* 🌟 Base Layout */
.dashboard-content {
  margin-top: 64px; /* account for navbar */
  margin-right: 280px; /* account for sidebar */
  padding: 30px 50px;
  box-sizing: border-box;
  direction: rtl;
  text-align: right;
  font-family: Tajawal;
  color: var(--Changing-Variables-Text-White);
  
  min-height: calc(100vh - 64px);
}

/* 🌟 If nested, override layout logic */
.dashboard-content .dashboard-content {
  margin: 0;
  padding: 0;
  background: transparent;
  min-height: auto;
}

/* 📱 Responsive Reset for Sidebar on Small Screens */
@media screen and (max-width: 1235px) {
  .dashboard-content {
    margin-right: 0;
    padding: 80px 30px 30px; /* Room for navbar */
    width: 100%;
  }
}

/* 🌟 Headings */
.dashboard-content-title,
.dashboard-b {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--Changing-Variables-Text-White);
}

.dashboard-content-subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
  color: var(--Changing-Variables-Text-White);
}

.dashboard-content-title-input {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  background: transparent;
  color: white;
  border: none;
  outline: none;
  text-align: right;
  margin-bottom: 20px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s;
}

.dashboard-content-title-input:focus {
  border-bottom: 2px solid var(--Changing-Variables-Buttons);
}

/* 🌟 Inputs & Description */
.dashboard-content-description,
.dashboard-content-input {
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  background-color: rgba(179, 179, 179, 0.5);
  color: white;
  font-family: Tajawal;
  margin-bottom: 20px;
  border: 2px solid transparent;
  transition: border 0.3s ease;
  box-sizing: border-box;
}

.dashboard-content-description:hover,
.dashboard-content-description:focus,
.dashboard-content-input:hover,
.dashboard-content-input:focus {
  border-color: var(--Changing-Variables-Buttons);
  background-color: rgba(179, 179, 179, 0.7);
}

.dashboard-content-description {
  min-height: 100px;
  resize: none;
  white-space: pre-wrap;
  overflow-y: hidden;
}

/* 🌟 Character Grid */
.dashboard-character-carousel {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
}

.dashboard-character-item {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  background-color: rgba(179, 179, 179, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.dashboard-character-item.upload:hover {
  background-color: rgba(200, 200, 200, 0.5);
}

.dashboard-character-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;
}

.dashboard-delete-button {
  position: absolute;
  top: 5px;
  left: 5px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
}

/* 🌟 Save Button */
.dashboard-save-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  font-size: 20px;
  font-weight: bold;
  color: var(--Changing-Variables-Button-Text);
  background-color: var(--Changing-Variables-Buttons);
  border-radius: 10px;
  cursor: pointer;
  margin-top: 40px;
  font-family: Poppins;
  border: 2px solid transparent;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.dashboard-save-button:hover {
  background-color: var(--Changing-Variables-Button-Text);
  color: var(--Changing-Variables-Buttons);
  border: 2px solid var(--Changing-Variables-Buttons);
}

.dashboard-save-button:hover .dashboard-icon {
  filter: brightness(0) invert(1);
}

/* 🌟 Social Platforms */
.dashboard-social-platform {
  border: 2px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.dashboard-social-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.dashboard-social-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.dashboard-social-name {
  font-size: 18px;
  font-weight: bold;
  color: var(--Changing-Variables-Text-White);
}

/* 🌟 Action Buttons */
.dashboard-toggle-btn,
.dashboard-delete-btn {
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  font-family: Tajawal;
  background: transparent;
  border-radius: 8px;
  transition: background 0.3s ease-in-out;
}

.dashboard-toggle-btn {
  color: var(--Changing-Variables-Text-White);
  border: 2px solid var(--Changing-Variables-Buttons);
}

.dashboard-toggle-btn:hover {
  background-color: var(--Changing-Variables-Buttons);
}

.dashboard-delete-btn {
  color: red;
  border: 2px solid red;
}

.dashboard-delete-btn:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

/* 🌟 Checkmarks */
.check-mark-parent {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
}

.check-mark-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check-mark-icon {
  width: 32px;
  height: 32px;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-mark-option span {
  font-size: 16px;
}
