:root {
  --primary-color: #4a1b4f;
  --secondary-color: #e6f8e6;
  --background-color: #ffffff;
  --text-color: #121212;
  --border-color: #e0e0e0;
  --faded-text-color: #b3b3b3;
  --faded-border-color: rgba(50, 50, 50, 0.5);
  --success-color: #0f9d58;
  --error-color: #db4c3f;
}

body:not(.dark-mode) {
  --text-color: #4a1b4f;
  --border-color: #4a1b4f;
  --faded-text-color: #777;
  --faded-border-color: rgba(179, 179, 179, 0.5);
}

.admins-list-container {
  padding: 24px;
  background: var(--background-color);
  border-radius: 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  direction: rtl;
  color: var(--text-color);
  transition: background-color 0.3s ease;
}

.admins-list-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: var(--primary-color);
}

.admin-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.admin-toolbar input,
.role-filter {
  padding: 10px 14px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.admin-toolbar input:focus,
.role-filter:focus {
  border-color: var(--primary-color);
}

.admins-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.admins-table th,
.admins-table td {
  padding: 14px 12px;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
}

.admins-table thead th {
  background-color: var(--primary-color);
  color: var(--background-color);
  font-weight: bold;
}

.admins-table tbody tr:hover {
  background-color: rgba(74, 27, 79, 0.05);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--border-color);
  transition: border-color 0.3s ease;
}

.admin-avatar:hover {
  border-color: var(--primary-color);
}

.admin-status {
  text-align: center;
}

.status-label {
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  margin-right: 8px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.dot.online {
  background-color: var(--success-color);
  box-shadow: 0 0 6px var(--success-color);
}

.dot.offline {
  background-color: var(--faded-text-color);
}

.role-badge {
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
  color: white;
}

.role-badge.ceo {
  color: var(--background-color);
  background-color: #4a1b4f;
}

.role-badge.super_admin {
  background-color: #6a1b9a;
}

.role-badge.editor {
  background-color: #0288d1;
}

.role-badge.writer {
  background-color: #388e3c;
}

.inline-role-dropdown {
  padding: 6px 10px;
  font-size: 13px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: #f9f9f9;
  outline: none;
  transition: background-color 0.3s ease;
}

.inline-role-dropdown:focus {
  background-color: #f0f0f0;
}

.toggle-button {
  padding: 6px 10px;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.toggle-button:hover {
  background-color: #3a1342;
}

.self-action-note {
  color: var(--faded-text-color);
  font-weight: bold;
  cursor: not-allowed;
  display: inline-block;
  padding: 6px 10px;
  font-size: 16px;
}

.self-action-note:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.self-action-note:hover::after {
  opacity: 1;
}

.last-seen {
  font-size: 12px;
  color: var(--faded-text-color);
  margin-top: 4px;
}

.self-row {
  background-color: rgb(241, 207, 237);
  font-weight: bold;
  color: var(--primary-color);
}

/* Mobile Responsive Styles */
@media (max-width: 506px) {
  .admins-list-container {
    padding: 16px;
  }

  .admins-list-container h2 {
    font-size: 20px;
  }

  .admin-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .admin-toolbar input,
  .role-filter {
    width: 100%;
  }

  .admins-table {
    overflow-x: auto;
    display: block;
  }

  .admins-table th,
  .admins-table td {
    padding: 12px 8px;
    font-size: 14px;
  }

  .admin-avatar {
    width: 40px;
    height: 40px;
  }

  .status-label {
    font-size: 12px;
  }

  .dot {
    width: 10px;
    height: 10px;
  }

  .role-badge {
    font-size: 12px;
  }

  .inline-role-dropdown {
    font-size: 12px;
  }

  .toggle-button {
    padding: 4px 8px;
    font-size: 12px;
  }

  .self-action-note {
    font-size: 14px;
  }

  .last-seen {
    font-size: 10px;
  }
}
