/* ArticleDetail.css */
.article-detail-container {
    width: 100%;
    
    box-sizing: border-box;
  }
  .authors{
    display: flex;
align-items: center;
gap: 32px;
  }
  /* Add padding for left and right */
  .article-detail-container main {
    padding-left: 150px;
    padding-right: 150px;
  }
  /* 1-column layout */
.article-layout.one-column {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjust spacing as needed */
  }
  
  .one-column-cell {
    width: 100%; /* Full width for 1-col mode */
  }
  
  /* 2-column layout */
  .article-layout.two-column {
    display: flex;
    gap: 20px; /* Adjust spacing as needed */
  }
  
  .two-column-cell {
    flex: 1; /* Equal width for both cells in 2-col mode */
  }
  
  /* Ensure images and text are responsive */
  .article-layout-image img {
    max-width: 100%;
    height: auto;
  }
  /* Line after header */
  .article-detail-container::after {
    content: "";
    background: var(--Changing-Variables-line-White, #FFF);
    width: 100%;
    height: 1px;
    margin: 16px 0;
  }
  
  /* Flex layout for roles */
  .roles-layout {
    display: flex;
    width: 1088px;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
  }
  
  .roles-layout div {
    color: var(--Changing-Variables-Placeholders-and-Faded-Text, #B3B3B3);
    text-align: center;
    font-family: Tajawal;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
  }
  
  /* Line after the roles section */
  .line-separator {
    background: var(--Changing-Variables-line-White, #FFF);
    width: 1088px;
    height: 1px;
    margin: 18px 0;
  }
  
  /* Title */
  .article-title {
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: center;
    font-family: Tajawal;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 18px;
  }
  
  /* Images layout */
  .article-images {
    display: flex;
    gap: 16px;
    margin-bottom: 18px;
  }
  
  .article-image {
    border-radius: 8px;
  }
  
  .article-image.single-image {
    width: 1080px;
    height: 320px;
  }
  
  .article-image.two-images {
    width: 536px;
    height: 320px;
    display: inline-flex;
    align-items: center;
  }
  
  /* Layout content */
  .article-layout {
    width: 1080px;
    max-width: 1080px;
    margin-bottom: 18px;
  }
  
  .article-layout-text {
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: right;
    font-family: Tajawal;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }
  
  .article-layout-image {
    width: 536px;
    height: 320px;
  }
  
  /* Line after the layout content */
  .layout-line {
    background: var(--Changing-Variables-line-White, #FFF);
    width: 1088px;
    height: 1px;
    margin: 18px 0;
  }
  
  /* Closure */
  .article-closure {
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: center;
    font-family: Tajawal;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
  }
  
  /* Line after closure */
  .closure-line {
    background: var(--Changing-Variables-line-White, #FFF);
    width: 1088px;
    height: 1px;
    margin-top: 16px;
  }
  