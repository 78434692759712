@import '../fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Figtree:ital,wght@0,300..900;1,300..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

.rating-section {
    padding: 40px;
    background-color: var(--Changing-Variables-Stroke-White);
    color: var(--Changing-Variables-Text-White);
    text-align: center;
}

.rating-section-title {
    color: var(--Changing-Variables-Text-White, #FFF);
    font-family: Tajawal;
    font-size: 32px;
    font-weight: 700;
}

.rating-section-subtitle {
    color: var(--Changing-Variables-Text-White, #FFF);
    font-family: Tajawal;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 10px;
}

.rating-card {
    position: relative;
    width: 1088px;
    height: 480px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    overflow: hidden;
}

.rating-container {
    position: relative;
    z-index: 1; /* Content above the overlay */
    display: flex;
    align-items: flex-start;
    gap: 20px;
    direction: rtl;
}

.rating-poster img {
    width: 240px;
    height: 360px;
    border-radius: 8px;
    object-fit: cover;
}

.rating-details {
    flex: 1;
    text-align: right;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between title, description, and genres */
}

.rating-title {
    font-family: Tajawal;
    font-size: 24px;
    font-weight: 700;
    color: #FFF;
}

.rating-description {
    font-family: Tajawal;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #FFF;
}

.rating-genres {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.rating-genre {
    background-color: #4A1B4F; /* Updated genre background */
    color: #FFF; /* Genre text is white */
    padding: 5px 15px;
    border-radius: 20px;
    font-family: Tajawal;
    font-size: 14px;
    font-weight: 700;
}

.rating-divider {
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
}

.rating-bottom-section {
    display: flex;
    justify-content: space-between; /* Adjust alignment */
    align-items: center;
    margin-top: 20px;
    flex-direction: row-reverse; /* Reverse the row to make buttons align right */
}

.rating-scores {
    display: flex;
    gap: 20px;
    justify-content: center; /* Center the ratings */
    margin-right: 20px; /* Slightly shift the scores to the left */
}

.rating-score-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    color: #FFF;
    text-align: right;
    font-family: poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
    color: #FFF;
    font-style: normal;
}

.rating-score-value {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #4A1B4F; /* Updated score background */
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    color: #FFF; /* Score text is white */
}

.rating-card-container {
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
}

.rating-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end; /* Align buttons to the right */
    margin-top: 10px; /* Optional: Adjust spacing above the buttons */
}

.rating-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #4A1B4F;
    background-color: #4A1B4F; /* Updated button background */
    color: #FFF; /* Button text is white */
    font-family: Tajawal;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.button-icon {
    width: 16px; /* Adjust size as needed */
    height: 16px;
    margin-left: 8px; /* Space between the icon and text */
    object-fit: contain;
    flex-shrink: 0;
}

.rating-button:hover {
    background-color: #FFF; /* Inverted hover background */
    color: #4A1B4F; /* Inverted hover text */
}


@media (max-width: 768px) {
    .rating-section {
      padding: 20px; /* Adjust padding */
    }
  
    .rating-card {
      width: 100%; /* Full width */
      height: auto; /* Allow flexible height */
      flex-direction: column; /* Stack content */
      padding: 10px;
    }
  
    .rating-container {
      flex-direction: column; /* Stack poster and details */
      gap: 10px;
    }
  
    .rating-poster img {
      width: 100%; /* Full width of container */
      height: auto; /* Maintain aspect ratio */
    }
  
    .rating-details {
      gap: 10px; /* Adjust spacing */
    }
  
    .rating-scores {
      flex-wrap: wrap; /* Allow wrapping for smaller screens */
      gap: 10px;
      justify-content: center; /* Center align */
    }
  
    .rating-actions {
      flex-wrap: wrap; /* Stack buttons */
      gap: 10px;
    }
  }
  