.articles-history-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #fff;
  font-family: "Tajawal", sans-serif;
}

.icon-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon-button.delete {
  background-color: #d9534f;
  color: white;
  border: none;
  margin-left: 6px;
  padding: 5px 8px;
  border-radius: 4px;
  cursor: pointer;
}

/* 🔹 Section Title */
.history-title {
  align-self: stretch;
  text-align: right;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

/* 🔹 Search Bar & Info */
.search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  text-align: right;
}

.search-bar-cms::placeholder {
  color: var(--Changing-Variables-Icons);
  opacity: 1;
}

.search-bar-cms {
  font-family: Tajawal;
  direction: ltr;
  font-style: normal;
  display: flex;
  padding: 10.5px 16px;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Changing-Variables-Stroke-border);
  background: var(--Changing-Variables-Faded-Bars);
  color: var(--Changing-Variables-Icons);
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  line-height: 150%;
}

.search-info {
  color: var(--Changing-Variables-Text-White);
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  margin-top: 5px;
  width: 100%;
}

/* 🔹 Articles Table */
.articles-history-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
}

.articles-table {
  width: 100%;
  border-collapse: collapse;
}

.articles-table th,
.articles-table td {
  padding: 10.5px 16px;
  border: 1px solid var(--Changing-Variables-Stroke-border);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  background: var(--table);
  
}

.articles-table th {
  background: var(--Changing-Variables-Faded-Bars);
  color: var(--Changing-Variables-Text-White);
  font-weight: 500;
}

/* 🔹 Action Buttons */
/* Fix for flex layout inside <td> */
.article-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--Changing-Variables-Icons);
  transition: 0.3s ease-in-out;
}

.icon-button.view {
  color: #0f9d58;
}

.icon-button.edit {
  color: #f4b400;
}

.icon-button:hover {
  opacity: 0.8;
}

/* 🔹 No Data Message */
.no-data {
  text-align: center;
  color: var(--Changing-Variables-Placeholders-and-Faded-Text);
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
}
.text-info{
  align-self: stretch;
  text-align: right;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}
/* Add this to your existing CSS */
/* Ensure the SVGs inherit the fill color and apply the filter */
.cms-icon {
  width: 1.2rem;
  height: 1.2rem;
  fill: var(--Changing-Variables-Icons);
  transition: fill 0.3s ease-in-out;
}

/* Light mode icon style */
body:not(.dark-mode) .cms-icon {
  fill: #4A1B4F; /* Change SVG color for light mode */
}

/* Hover effect */
.icon-button:hover .cms-icon {
  fill: var(--Changing-Variables-Button-Text);
}




/* ✅ Responsive Design */
@media screen and (max-width: 768px) {
  .articles-table th,
  .articles-table td {
    padding: 8px;
    font-size: 14px;
  }

  .icon-button {
    font-size: 1rem;
  }
}
