.grid-editor-container {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 2px solid rgba(179, 179, 179, 0.5);
  padding: 20px;
  margin: auto;
}

.grid-row-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.delete-row-button {
  background: red;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 5px; /* Adds space between button and grid row */
}

.delete-row-button:hover {
  background: darkred;
}

.grid-row-container {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
 
}

.add-row-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.add-row-buttons button {
  padding: 10px 15px;
  border-radius: 8px;
  border: none;
  background: #4A1B4F;
  color: white;
  cursor: pointer;
}

.add-row-buttons button:hover {
  background: white;
  color: #4A1B4F;
}
