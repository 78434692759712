/* Sidebar.css */
.dashboard-sidebar {
  width: 280px;
  height: calc(100vh - 64px);
  background: var(--Changing-Variables-Stroke-White);
  position: fixed;
  top: 64px;
  right: 0;
  padding: 24px 0;
  border-left: 1px solid var(--Changing-Variables-Stroke-border);
  z-index: 999;
  direction: rtl;
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
}

.dashboard-sidebar-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
}

.dashboard-sidebar-item {
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-family: Tajawal;
  font-size: 14px;
  color: var(--Changing-Variables-Text-White);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  position: relative;
}

.dashboard-sidebar-item:hover {
  background-color: var(--Changing-Variables-Faded-Bars);
}

.dashboard-sidebar-item.active {
  background-color: var(--Changing-Variables-Buttons);
  color: var(--Changing-Variables-Button-Text);
}

.dashboard-sidebar-item.disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* Mobile Toggle Button */
.sidebar-toggle {
  display: none;
  background: var(--Changing-Variables-Buttons);
  color: var(--Changing-Variables-Button-Text);
  border: none;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  position: fixed;
  top: 12px;
  right: 12px;
  z-index: 1001;
  font-family: Tajawal;
}

/* Responsive */
@media (max-width: 1235px) {
  .sidebar-toggle {
    display: block;
  }

  .dashboard-sidebar {
    transform: translateX(100%);
    width: 280px;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  }

  .dashboard-sidebar.open {
    transform: translateX(0);
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(8px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}