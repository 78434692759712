.about-us-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-aligns content */
  gap: 32px; /* Space between elements */
  padding: 40px;
  background-color: var(--Changing-Variables-Stroke-White); /* Adapts to light or dark mode */
  text-align: center;
}

.about-us-box {
  display: flex;
  flex-direction: column;
  width: 1088px;
  padding: 32px; /* Padding inside the box */
  gap: 32px; /* Space between elements inside the box */
  text-align: justify; /* Slightly faded background */
  border-radius: 10px; /* Rounded corners */
  align-items: center; /* Center text alignment */
}

.about-us-title {
  font-family: Tajawal;
  font-size: 32px;
  font-weight: 700;
  color: var(--Changing-Variables-Text-White); /* Adapts to light or dark mode */
}

.about-us-paragraph {
  color: var(--Changing-Variables-Text-White); /* Adapts to light or dark mode */
  text-align: justify; /* Justifies text for balanced alignment */
  direction: rtl; /* Ensures proper right-to-left flow */
  font-family: Tajawal;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  white-space: pre-line; /* Preserves spaces and new lines */
  margin-bottom: 16px; /* Adds spacing between paragraphs */
}

.about-us-button {
  margin-top: 65px;
    display: inline-block;
    width: 155vh;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid var(--Changing-Variables-Stroke-Button, #121212);
    background: var(--Changing-Variables-Buttons, #FFF);
    color: var(--Changing-Variables-Button-Text, #4A1B4F);
    font-family: Tajawal;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.about-us-button:hover {
  background: var(--Changing-Variables-Button-Text);
  color: var(--Changing-Variables-Text-White);
}

@media (max-width: 768px) {
  .about-us-section {
    padding: 20px;
    gap: 20px;
  }

  .about-us-box {
    width: 100%; /* Full width */
    padding: 16px; /* Reduce padding */
  }

  .about-us-title {
    font-size: 24px; /* Adjust font size */
  }

  .about-us-paragraph {
    font-size: 14px; /* Scale down */
    line-height: 1.5;
  }

  .about-us-button {
    width: 100%; /* Full width */
    padding: 8px; /* Adjust padding */
  }
}
