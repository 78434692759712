/* 🌟 Add Article Page */
.add-article-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
   
    margin: auto;
    padding: 20px;
    color: white;
    font-family: "Tajawal", sans-serif;
}

.participation-container {
    display: flex;
    width: 800px; /* Adjust width as needed */
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  
  .participation-box, .participant-name-box {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .participation-label, .participant-label {
    color: var(--Changing-Variables-Text-White, #FFF);
    font-family: Tajawal;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    white-space: nowrap;
  }
  
  .participation-input, .participant-input {
    
    display: flex-end;
    width: 200px;
    padding: 10.5px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Changing-Variables-Faded-Bars, rgba(179, 179, 179, 0.50));
  }
  
.category-section {
  display: flex;
  width: 379px;
  justify-content: space-between;
  align-items: center;
}
.article-input-filter{
    width: 100%;
    color: var(--Changing-Variables-Placeholders-and-Faded-Text, #B3B3B3);
    text-align: center;
    font-family: Tajawal;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    padding: 10.5px 16px;
    appearance: none; 
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid rgba(179, 179, 179, 0.5);
    background: transparent;
    font-size: 18px;
    color: white;
    text-align: right;
}


  
  /* Custom dropdown styling */
  .article-input-filter option {
    border-radius: 8px;
    background: var(--Changing-Variables-Faded-Bars, white);
    display: flex;
    width: 238px;
    padding: 10.5px 16px;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  /* 🔹 Input Fields */
 
  
  .article-input-filter:hover,
  .article-input-filter:focus {
      border: 2px solid var(--Changing-Variables-Buttons);
  }

  
.category-label {
  color: var(--Changing-Variables-Text-White, #FFF);
  text-align: right;
  font-family: Tajawal;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  white-space: nowrap;
}

.category-dropdown {
    display: flex;
    width: 238px;
    
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
background: var(--Changing-Variables-Faded-Bars, rgba(179, 179, 179, 0.50));
}



/* Custom dropdown styling */
.article-input option {
  border-radius: 8px;
  background: var(--Changing-Variables-Faded-Bars, rgba(179, 179, 179, 0.50));
  display: flex;
  width: 238px;
  padding: 10.5px 16px;
  justify-content: space-between;
  align-items: flex-start;
}

/* 🔹 Input Fields */
.article-input {
    width: 50%;
    padding: 12px;
    border-radius: 8px;
    border: 2px solid rgba(179, 179, 179, 0.5);
    background: transparent;
    font-size: 18px;
    color: white;
    text-align: right;
    
}
.article-input::placeholder {
    color: var(--Changing-Variables-Placeholders-and-Faded-Text, #B3B3B3);
    text-align: right; /* Ensures right-aligned Arabic text */
    font-family: Tajawal;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    opacity: 1; /* Ensures full visibility */
}

.article-input:hover,
.article-input:focus {
    border: 2px solid var(--Changing-Variables-Buttons);
}

/* 🔹 Publish Status */
.publish-status-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.publish-options {
    display: flex;
    
    gap: 15px;
    width: 80%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.publish-options .check-mark-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
}
.category-select {
    display: flex;
    width: 238px;
    padding: 10.5px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: rgba(179, 179, 179, 0.50);
    color: #B3B3B3;
    text-align: center;
    font-family: Tajawal;
    font-size: 18px;
  }
  
/* ✅ Custom Checkmark Icon */
.custom-checkmark-icon {
    width: 32px;
    height: 32px;
    position: relative;
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

/* ✔️ Checkmark Selected State */
.check-mark-container.selected .custom-checkmark-icon {
    border-color: var(--Changing-Variables-Buttons);
}

/* 🔹 Submit Buttons */
.article-buttons {
    display: flex;
    gap: 10px;
}

.review-button,
.publish-button {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.review-button {
    background: var(--Changing-Variables-Buttons);
    color: var(--Changing-Variables-Button-Text);
}

.review-button:hover {
    background: var(--Changing-Variables-Button-Text);
    color: white;
}

.publish-button {
    background: #4A1B4F;
    color: white;
}

.publish-button:hover {
    background: white;
    color: #4A1B4F;
}


.dashboard-custom-images {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard-custom-images-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard-custom-images-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.dashboard-custom-images-item {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-custom-images-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dashboard-custom-images-preview {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;
}

.dashboard-custom-images-delete {
  position: absolute;
  top: 5px;
  left: 5px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.dashboard-custom-images-delete:hover {
  opacity: 1;
}

.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.upload:hover {
  background-color: #f0f0f0;
  border-color: #aaa;
}

.dashboard-custom-images-icon {
  width: 50px;
  height: 50px;
}

@keyframes rainbow {
  0% { border-color: red; }
  14% { border-color: orange; }
  28% { border-color: yellow; }
  42% { border-color: green; }
  57% { border-color: blue; }
  71% { border-color: indigo; }
  85% { border-color: violet; }
  100% { border-color: red; }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite, rainbow 3s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
/* Existing styles above... */

/* 📱 Responsive Design for Add Article Page (Below 1235px) */
@media screen and (max-width: 507px) {
  .participation-container,
  .category-section,
  .publish-options,
  .article-buttons {
    flex-direction: column;
    align-items: flex-start;
    width: 100% !important;
    gap: 12px;
  }

  .participation-input,
  .participant-input,
  .category-select,
  .article-input,
  .article-input-filter,
  .category-dropdown {
    width: 100% !important;
  }

  .add-article-container {
    padding: 15px;
  }

  .article-buttons {
    width: 100%;
    justify-content: space-between;
  }

  .review-button,
  .publish-button {
    width: 100%;
  }

  .dashboard-custom-images-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .dashboard-custom-images-item {
    width: 80px;
    height: 80px;
  }

  .dashboard-custom-images-icon {
    width: 40px;
    height: 40px;
  }

  .check-mark-parent {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-filter-button {
    width: 100% !important;
  }
}

/* Optional: Adjust spinner size for small screens */
@media screen and (max-width: 768px) {
  .loading-spinner {
    width: 40px;
    height: 40px;
    border-width: 4px;
  }

  .loading-text {
    font-size: 14px;
  }
}


/* 



.dashboard-character-carousel {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
}


  .dashboard-character-item {
    width: 100px;
    height: 100px;
  }




  .dashboard-character-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;
}

.dashboard-delete-button {
  position: absolute;
  top: 5px;
  left: 5px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
}


*/