/* Hero Section Styling */
.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 250px;
  background-color: var(--Changing-Variables-Stroke-White); /* Matches the header background */
  color: var(--Changing-Variables-Text-White);
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative; /* Allows pseudo-element placement */
}

.hero::after {
  content: '';
  display: block;
  width: 100%; /* Matches the header's line width */
  height: 1px; /* Matches the header's line height */
  background-color: var(--Changing-Variables-Stroke-Button); /* Matches the header's line color */
  margin: 0 auto; /* Centers the line */
  position: absolute;
  bottom: 0; /* Places the line at the bottom of the hero section */
  left: 50%; /* Centers horizontally */
  transform: translateX(-50%); /* Centers accurately */
}

/* Content Styling */
.hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 14px; /* Matches Figma gap */
  max-width: 50%;
  text-align: right;
  color: var(--Changing-Variables-Placeholders-and-Faded-Text); /* Subtle faded color */
}

.hero-content h5,
.hero-content p {
  text-align: right;
  font-family: Tajawal;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  color: var(--Changing-Variables-Placeholders-and-Faded-Text); /* Matches faded text */
}

.hero-content h1 {
  text-align: right;
  font-family: Tajawal;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--Changing-Variables-Text-White); /* Main text white */
}
.her{
  display: flex;
  gap:100px;
  flex-direction: row-reverse
}
.hero-button {
  display: flex;
  padding: 8px 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background-color: var(--Changing-Variables-Buttons); /* Matches header button */
  color: var(--Changing-Variables-Button-Text, #4A1B4F); /* Button text color */
  border: 1px solid var(--Changing-Variables-Stroke-Button); /* Matches stroke color */
  font-family: Tajawal;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.hero-button:hover{
  background-color: var(--Changing-Variables-Button-Text);
  color: var(--Changing-Variables-Buttons);
}
.hero-button-2:hover{
  color: var(--Changing-Variables-Button-Text);
  background-color: var(--Changing-Variables-Buttons);
 
}

.hero-button-2{
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--Changing-Variables-Stroke-Button);
  display: flex;
padding: 8px 20px;
justify-content: center;
align-items: center;

gap: 10px;
color: var(--Changing-Variables-Buttons);
width: 300px;
height: auto;
/* Others - Tajwal/Button 14 */
font-family: Tajawal;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Image Container */
.hero-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 616.45px;
  height: 634px;
  overflow: hidden;
}

/* Hero Image (with fade-in transition) */
/* Hero Image (with fade-in transition) */
.hero-media {
  position: absolute; /* Position images on top of each other */
  width: 226px;
  height: 460px;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  z-index: 1; /* Make sure images stack properly */
}



/* Background Styling */
.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 /* Push it behind everything */
  pointer-events: none; /* Prevents it from blocking clicks */
}

.background-image {
  margin-right: 700px;
  width: 677px; /* Matches container width */
  object-fit: cover; /* Ensures it covers the container size */
}

/* Animations */
@keyframes fadeRightIn {
  0% {
    opacity: 0;
    transform: translateX(-110%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeRightOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.hero-media.fade-right-in {
  animation: fadeRightIn 1s ease-in-out forwards;
}

.hero-media.fade-right-out {
  animation: fadeRightOut 1s ease-in-out forwards;
}

.hero-media.fade-in {
  opacity: 1;
  transform: translateX(0);
}

.hero-media.fade-out {
  opacity: 0;
  pointer-events: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    flex-direction: column; /* Stack content */
    padding: 20px; /* Reduce padding */
    text-align: center;
  }

  .hero-content {
    align-items: center; /* Center content */
    max-width: 100%; /* Full width */
    text-align: center; /* Center align text */
  }

  .hero-content h1 {
    font-size: 36px; /* Scale down */
  }

  .hero-content h5,
  .hero-content p {
    font-size: 16px;
  }

  .hero-image-container {
    width: 100%;
    height: auto; /* Scale image proportionally */
  }

  .hero-media {
    width: 150px; /* Adjust size */
    height: auto;
  }
}
