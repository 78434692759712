/* Articles Page Styles */
.articles-page-section {
    padding: 40px;
    background-color: var(--Changing-Variables-Stroke-White);
    color: var(--Changing-Variables-Text-White);
    text-align: center;
    position: relative;
}

.articles-page-subtitle {
    font-size: 18px;
    margin-bottom: 20px;
    color: var(--Changing-Variables-Placeholders-and-Faded-Text);
}

/* Category Filter Buttons */
.articles-page-category-buttons {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px;
    margin-bottom: 30px;
}


.category-button {
    border-radius: 6px;
    border: 1px solid transparent;
    background: none;
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Changing-Variables-Buttons);
    font-family: Tajawal;
    font-size: 14px;
    font-weight: 700;
    transition: all 0.3s ease;
    cursor: pointer;
}

.category-button:hover {
    color: var(--Changing-Variables-Button-Text);
    background-color: var(--Changing-Variables-Buttons);
}

.category-button.active {
    color: var(--Changing-Variables-Button-Text);
    background-color: var(--Changing-Variables-Buttons);
}

/* Specific Tag Styles */
.article-tag-custom.breaking {
    display: flex;
    padding: 0px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #F9F871;
    color: var(--Dark, #121212);
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.article-tag-custom.latest {
    display: flex;
    padding: 0px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #FFF;
    color: var(--Primary, #4A1B4F);
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.article-tag-custom.common {
    display: flex;
    padding: 0px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #FFF;
    color: var(--Primary, #4A1B4F);
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


/* Articles Grid */
/* Articles Grid */
.articles-page-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Ensure 4 cards per row */
    justify-content: center;
    gap: 32px; /* Gap between cards */
    margin-top: 50px; /* Increased margin from tags */
    padding: 0 180px; /* Padding from left and right */
}

/* Article Card */
.article-card-custom {
    width: 300px; /* Fixed width for card */
    height: 450px;
    border-radius: 8px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 50%,
        rgba(0, 0, 0, 0.8) 66.27%
      ),
      var(--Changing-Variables-Faded-Bars);
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Content remains at the bottom */
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out; /* Zoom-in on hover */
}

.article-card-custom:hover {
    transform: scale(1.05); /* Zoom-in effect */
}


/* Article Content */
.article-card-custom-content {
    z-index: 1;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

/* Tag Styles */
.article-tag-custom {
    display: flex;
    align-items: center;
    border-radius: 16px;
    background: var(--Primary, #4A1B4F);
    width: 92px;
    padding: 0px 24px;
    gap: 10px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    color: #fff;
}

/* Title and Description */
.article-card-custom-title {
    font-size: 18px;
    font-family: Poppins;
    font-weight: 700;
    color: #fff;
    line-height: 1.4;
}

.article-card-custom-description {
    font-size: 18px;
    direction: rtl;
    font-family: Poppins;
    font-weight: 400;
    color: #ccc;
    margin: 0;
}

/* Date Style */
.article-date  {
   
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: auto;
}


/* Stats Section */
.article-stats-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-radius: 16px;
    margin-top: auto;
    width: 100%;
}

.article-stats-custom .article-likes,
.article-stats-custom .article-comments {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #b3b3b3;
}

.article-stats-custom img {
    width: 15px;
    height: 15px;
}

.article-card-custom-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.article-card-custom-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Footer */
.articles-page-footer {
    margin-top: 40px;
}

/* Articles Page Tags */
.articles-page-tags {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-end; /* Align tags to the right */
}
/* Style for clickable article card */
.article-card-custom-link {
    text-decoration: none; /* Remove underline on link */
    color: inherit; /* Inherit text color */
}

/* No Articles Message */
.no-articles-message {
    text-align: center;
    margin-top: 50px;
}

.clear-filters-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: var(--Changing-Variables-Buttons);
    color: var(--Changing-Variables-Button-Text);
    cursor: pointer;
    font-family: Tajawal;
    font-size: 14px;
    font-weight: 700;
    transition: background-color 0.3s ease;
}

.clear-filters-button:hover {
    background-color: var(--Changing-Variables-Button-Hover);
}
