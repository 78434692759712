/* General Side Menu Styles */
.side-menu {
  display: flex;
  width: 90px; /* Default width when closed */
  height: 90vh; /* Full height for responsiveness */
  max-height: 100%; /* Ensure it does not overflow */
  padding: 32px 16px; /* Adjust padding for better spacing */
  flex-direction: column;
  align-items: center; /* Center alignment */
  flex-shrink: 0;
  border-radius: 0px 32px 32px 0px; /* Keep border shape */
  border: 1px solid var(--Changing-Variables-Stroke-Button); /* Matches theme stroke color */
  background: var(--Changing-Variables-Stroke-White); /* Matches header background */
  position: fixed;
  top: 54px; /* Positioned 54px from the top */
  left: 0;
  transition: width 0.5s ease, height 0.3s ease, background-color 0.3s ease; /* Smooth background transitions */
  overflow-y: auto; /* Enable scrolling for content overflow */
  z-index: 1000;
}

.side-menu.open {
  width: 414px; /* Full width when open */
}

/* Hide Scrollbar */
.side-menu::-webkit-scrollbar {
  display: none;
}

.side-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Slide Menu Toggle and Logo */
.side-menu-toggle-container {
  display: flex;
  align-items: center;
  gap: 35%; /* Consistent spacing */
  color: var(--Changing-Variables-side-menu);
  width: 100%;
  margin-bottom: 30px; /* Space below */
}

.side-menu-toggle {
  color: var(--Changing-Variables-side-menu);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.side-menu-toggle img {
  width: 24px;
  height: 24px;
  filter: var(--Changing-Variables-Icons-Filter, none); /* Dynamic color adjustment for light/dark mode */
  transition: filter 0.3s ease;
}

body:not(.dark-mode) .side-menu-toggle img {
  filter: brightness(0) saturate(100%) invert(15%) sepia(30%) saturate(223%) hue-rotate(290deg) brightness(94%) contrast(82%); /* Matches #4A1B4F */
}

.side-menu-logo {
  display: none;
}

.side-menu.open .side-menu-logo {
  display: block;
}

.side-menu-logo img {
  width: 120px; /* Adjust size for the logo */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
  filter: var(--Changing-Variables-Icons-Filter, none); /* Dynamic color adjustment */
  transition: filter 0.3s ease;
}

/* Profile Section */
.side-menu-profile-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px; /* Space below profile section */
}

.side-menu-profile-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.side-menu-profile-info {
  display: none;
}

.side-menu.open .side-menu-profile-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--Changing-Variables-side-menu); /* Matches text color */
  font-size: 14px; /* Adjust for better scaling */
}

.side-menu.open .side-menu-profile-info span {
  color: var(--Changing-Variables-side-menu, #4A1B4F); /* Light mode username color */
  font-family: Tajawal;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.side-menu.open .side-menu-profile-info small {
  color: var(--Changing-Variables-Placeholders-and-Faded-Text, #494A49); /* Light mode nickname color */
  font-family: Tajawal;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
}

/* Dark/Light Mode Toggle */
.side-menu-theme-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 32px;
}

.side-menu-theme-toggle img {
  width: 20px; /* Adjust size */
  height: 20px;
  flex-shrink: 0;
  transition: filter 0.3s ease; /* Smooth color transitions for non-SVG icons */
}

/* Menu Links */
.side-menu-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  list-style: none; /* Remove dots from menu links */
  padding: 0; /* Remove default padding */
}

.side-menu.open .side-menu-links {
  align-items: flex-start;
}

.side-menu-links li {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align icons and text */
  width: 100%;
}

.side-menu-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--Changing-Variables-side-menu); /* Matches theme text color */
  font-family: Tajawal, sans-serif;
  font-size: 16px;
  font-weight: 700;
  gap: 8px;
  padding: 4px 0; /* Consistent vertical padding for links */
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for text color */
}

.side-menu-links li a:hover {
  transform: translateY(-2px); /* Slight hover effect without disrupting layout */
}

/* SVG Icon Styling */
.side-menu-links li .side-menu-icon,
.side-menu-footer li .side-menu-icon {
  width: 24px;
  height: 24px;
  fill: var(--Changing-Variables-Icons); /* Icon color based on theme */
  transition: fill 0.3s ease; /* Smooth color transitions */
}

body:not(.dark-mode) .side-menu-links li .side-menu-icon,
body:not(.dark-mode) .side-menu-footer li .side-menu-icon {
  fill: #4A1B4F; /* Change SVG color for light mode */
}

/* Footer Links */
.side-menu-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto; /* Push footer to the bottom */
  gap: 24px; /* Space between footer items */
  list-style: none; /* Remove list-style (dots) */
  padding: 0; /* Remove default padding */
  width: 100%; /* Ensure it spans the full width */
}

.side-menu.open .side-menu-footer {
  align-items: flex-start; /* Align footer links to the left like the menu links */
}

.side-menu-footer li {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align icon and text horizontally */
  width: 100%; /* Full width for consistency */
}

/* Footer Buttons (Settings & Logout) */
.side-menu-footer button {
  background: none; /* Remove background */
  border: none; /* Remove border */
 /* Add padding similar to other links */
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  color: var(--Changing-Variables-side-menu); /* Match text color */
  font-family: Tajawal, sans-serif;
  font-size: 16px;
  font-weight: 700;
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for text color */
}

.side-menu-footer button:hover {
  transform: translateY(-2px); /* Slight hover effect */
}

.side-menu-footer button .side-menu-icon {
  width: 24px;
  height: 24px;
  fill: var(--Changing-Variables-Icons); /* Icon color */
  transition: fill 0.3s ease; /* Smooth color transitions */
}

body:not(.dark-mode) .side-menu-footer button .side-menu-icon {
  fill: #4A1B4F; /* Change SVG color for light mode */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .side-menu {
    width: 70px; /* Narrow menu for smaller screens */
    height: 100vh;
    padding: 20px 10px;
    background: var(--Changing-Variables-Stroke-White); /* Matches theme background */
  }

  .side-menu.open {
    width: 250px; /* Adjust expanded width */
  }

  .side-menu-links,
  .side-menu-footer {
    gap: 16px; /* Reduce spacing */
  }

  .side-menu-links li a,
  .side-menu-footer li a {
    font-size: 14px; /* Scale down font size */
  }

  .side-menu-logo img {
    width: 80px; /* Adjust size */
  }

  .side-menu-profile-avatar {
    width: 32px;
    height: 32px;
  }
}
