@import '../fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
.character-section {
    padding: 40px;
    background-color: var(--Changing-Variables-Stroke-White);
    color: var(--Changing-Variables-Text-White);
    text-align: center;
  }
  
  .character-section-title {
    align-self: stretch;
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .character-section-subtitle {
    align-self: stretch;
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: center;
    font-family: Tajawal;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 10px;
  }
  
  .character-container {
    margin-top: 51.5px;
    margin-right: 200px;
    display: flex;
    align-items: flex-start;
    gap: 43px; /* Spacing between text and card */
    direction: rtl; /* Right-to-left layout */
    /* Ensures the container stretches */
  }
  
  .character-details {
    flex: 1; /* Allows the container to grow */
    text-align: right; /* Aligns text to the right */
    padding: 0;
    margin: 0;
    width: 100%; /* Ensures the container takes up full width */
  }
  
  .character-name {
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: right;
    font-family: Tajawal;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .character-world-title {
    margin-bottom: 20px;
    width: 100%; /* Ensures it stretches */
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
  }
  
  .character-world-description {
    width: 80%;
    text-align: right;
    color: var(--Changing-Variables-Text-White, #FFF);
    font-family: Tajawal;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* Improves readability */
    display: flex;
    flex-direction: column; /* Stack lines vertically */
    gap: 10px; /* Add spacing between lines */
  }
  .line-break {
    display: block; /* Ensure each line behaves like a block element */
  }
  .actor-section {

    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    width: 100%; /* Stretch the section */
  }
  
  .character-actor-title {
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .character-learn-more {
    margin-right: 50%;
    display: flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid var(--Changing-Variables-Stroke-Button, #121212);
    background: var(--Changing-Variables-Buttons, #FFF);
    color: var(--Changing-Variables-Button-Text, #4A1B4F);
    font-family: Tajawal;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
  
  .character-learn-more:hover {
    background: var(--Changing-Variables-Button-Text);
    color: var(--Changing-Variables-Text-White);
  }
  
  .character-card {
    width: 248px;
    height: 400px;
    flex-shrink: 0;
    border-radius: 8px;
    background: rgb(51, 54, 185) 50% / cover no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .character-card-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  
  .character-card-info {
    position: relative;
    z-index: 1;
    font-family: Tajawal;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--Changing-Variables-Text-White);
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .character-card-info li {
    margin-bottom: 5px;
  }
  
  .character-actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .character-guide {
    margin-top: 65px;
    display: flex;
    width: 155vh;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid var(--Changing-Variables-Stroke-Button, #121212);
    background: var(--Changing-Variables-Buttons, #FFF);
    color: var(--Changing-Variables-Button-Text, #4A1B4F);
    font-family: Tajawal;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
  
  .character-guide:hover {
    background: var(--Changing-Variables-Button-Text);
    color: var(--Changing-Variables-Text-White);
  }
  