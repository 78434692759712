/* General Footer Styles */
.footer {
  background-color: var(--Changing-Variables-Stroke-White);
  color: var(--Changing-Variables-Text-White);
  text-align: center;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Navigation Links */
.footer-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  direction: rtl;
  flex-wrap: wrap;
}

.footer-navigation a {
  color: var(--Changing-Variables-Text-White, #FFF);
  font-family: Tajawal;
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
  transition: color 0.3s ease;
}

body:not(.dark-mode) .footer-navigation a {
  color: #4A1B4F;
}

.footer-navigation a:hover {
  text-decoration: underline;
}

/* Social Media Section */
.footer-social {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.footer-heading {
  margin-top: 100px;
  margin-bottom: 46px;
  color: var(--Changing-Variables-Text-White, #FFF);
  font-family: Tajawal;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  transition: color 0.3s ease;
}

body:not(.dark-mode) .footer-heading {
  color: #4A1B4F;
}

/* Social Icons */
.social-icons {
  display: inline-flex;
  height: 48.131px;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

.social-icons img {
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, filter 0.3s ease;
}

/* Dark Mode Icon */
.social-icon-img.dark-mode-icon {
  filter: brightness(0) invert(1); /* Forces the icon to be white */
}

/* Light Mode Icon */
.social-icon-img.light-mode-icon {
  filter: brightness(0) invert(0); /* Forces the icon to be black */
}

.social-icons img:hover {
  transform: scale(1.1);
}

/* Copyright Section */
.footer-copyright {
  font-family: Tajawal;
  font-size: 14px;
  font-weight: 400;
  color: var(--Changing-Variables-Placeholders-and-Faded-Text);
  transition: color 0.3s ease;
}

body:not(.dark-mode) .footer-copyright {
  color: #777;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .footer {
    padding: 20px;
    gap: 20px;
  }

  .footer-navigation {
    flex-wrap: wrap;
    gap: 16px;
  }

  .footer-navigation a {
    font-size: 16px;
  }

  .social-icons {
    gap: 16px;
  }

  .social-icons img {
    width: 36px;
    height: 36px;
  }

  .footer-heading {
    font-size: 20px;
  }
}