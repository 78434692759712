.grid-cell {
  border: 2px solid rgba(179, 179, 179, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  height: auto;
  position: relative;
  padding: 10px;
  /* Light background for better visibility */
}

/* Full-width cell for one-column layout */
.grid-row.col-1 .grid-cell {
  width: 100%;
}

/* Fixed-width cell for two-column layout */
.grid-row.col-2 .grid-cell {
  width: calc(50% - 15px);
}

.cell-options {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.cell-options button {
  padding: 10px 16px;
  border-radius: 8px;
  background: #4A1B4F;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.cell-options button:hover {
  background: #6a1b9a;
  color: white;
  transform: scale(1.05);
}
.tox-tinymce {
  width: 100% !important; /* Full width */
}


.image-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  flex-direction: column;
}

/* Hide default file input */
.image-upload input[type="file"] {
  display: none;
}

/* Styled upload button */
.upload-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 45px;
  background: #6a1b9a;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.2s ease;
  text-align: center;
  user-select: none;
}

.upload-button:hover {
  background: #4A1B4F;
  transform: scale(1.05);
}

/* Uploaded Image Preview */
.uploaded-image {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: 8px;
}

.image-preview {
  position: relative;
  width: 100%;
  height: 320px;
}

/* Delete Button */
.delete-image-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  opacity: 0.7;
  transition: opacity 0.3s ease, transform 0.2s ease;
}

.delete-image-button:hover {
  opacity: 1;
  transform: scale(1.1);
}
