/* Editable input fields and save button */
.info-row input{
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  width: 100%;
  text-align: center;
}


.save-button {
  margin-top: 10px;
  background-color: #4a1b4f;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #762a69;
}

.edit-icon {
  cursor: pointer;
}

.edit-icon img {
  width: 20px;
  height: 20px;
}


.settings-container {
  flex-grow: 1; /* Pushes footer to the bottom dynamically */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 50px; /* Adds spacing to avoid content overlapping with footer */
}

/* Make sure the footer stays at the bottom */
.footer-container {
    width: 10%;
    margin-top: auto; /* Push the footer down */
}

  /* Header Section */
  .settings-header {
    width: 100%;
    height: 240px;
    background-color: var(--Changing-Variables-Stroke-White); /* Dynamic background */
    overflow: hidden;
    position: relative;
    transform: translateY(-15px);
  }
  
  /* Header Image */
  .header-background img {
    width: 100%;
    height: calc(100% + 15px);
    object-fit: cover;
    transform: translateY(15px);
  }
  .react-datepicker__input-container input {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    border: none;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    text-align: left;
    cursor: pointer;
    
  }
  
  /* Logo and Text inside Header */
  .header-logo-container {
    position: absolute;
    left: 170px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  /* Logo Styling */
  .header-logo {
    height: 80px;
  }
  
  /* Header Text */
  .header-text {
    color: var(--Changing-Variables-Text-White);
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-left: 10px;
  }
  
  /* Profile Picture - Half Inside Header, Half Outside */
 
  /* Profile Picture */
/* Profile Container */
.profile-container {
  position: absolute;
  top: calc(240px - 100px); /* Adjust vertical positioning */
  left: 50%;
  width: 200px;  /* Adjusted as per your request */
  height: 200px; /* Adjusted as per your request */
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  z-index: 2;
  border-radius: 50%;  /* Retain circular shape */
  overflow: hidden; /* Ensures no overflow from the circle */
  background-color: transparent; /* No background color */
  cursor: pointer;  /* Changes cursor to indicate clicking for upload */
}

/* Profile Picture */
.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image covers the container */
  background-color: transparent; /* Ensures no background */
}

/* Hover Overlay */
.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Initially hidden */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  transition: opacity 0.3s ease-in-out;
}

.profile-container:hover .hover-overlay {
  opacity: 1; /* Show on hover */
}

/* Icon and Text Container on Hover */
.icon-container {
  display: flex;
  width: 115px;
  flex-direction: column;
  align-items: center;
  gap: 6px; /* Gap between the icon and text */
  text-align: center;
}

/* Icon Style */
.icon-container .upload-icon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
}

/* Text Style */
.icon-container span {
  color: var(--White, #FFF);
  font-family: Tajawal, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  text-align: center;
  white-space: nowrap; /* Ensures text stays in one line */
}

/* Hidden file input */
#file-input {
  display: none;
}

/* Icon and Text Container on Hover */
.icon-container {
  display: flex;
  width: 115px;
  flex-direction: column;
  align-items: center;
  gap: 6px; /* Gap between the icon and text */
  text-align: center;
}

/* Icon Style */
.icon-container .upload-icon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
}

/* Text Style */
.icon-container span {
  color: var(--White, #FFF);
  font-family: Tajawal, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  text-align: center;
  white-space: nowrap; /* Ensures text stays in one line */
}

/* Hidden file input */
#file-input {
  display: none;
}


/* Icon and Text Container on Hover */
.icon-container {
  display: flex;
  width: 115px;
  flex-direction: column;
  align-items: center;
  gap: 6px; /* Gap between the icon and text */
  text-align: center;
}

/* Icon Style */
.icon-container .upload-icon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
}

/* Text Style */
.icon-container span {
  color: var(--White, #FFF);
  font-family: Tajawal, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  text-align: center;
  white-space: nowrap; /* Ensures text stays in one line */
}

/* Hidden file input */
#file-input {
  display: none;
}

/* Icon and Text Container on Hover */
.icon-container {
  display: flex;
  width: 115px;
  flex-direction: column;
  align-items: center;
  gap: 6px; /* Gap between the icon and text */
  text-align: center;
}

/* Icon Style */
.icon-container .upload-icon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
}

/* Text Style */
.icon-container span {
  color: var(--White, #FFF);
  font-family: Tajawal, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  text-align: center;
  white-space: nowrap; /* Ensures text stays in one line */
}

/* Hidden file input */
#file-input {
  display: none;
}

/* Icon and Text Container on Hover */
.icon-container {
  display: flex;
  width: 115px;
  flex-direction: column;
  align-items: center;
  gap: 6px; /* Gap between the icon and text */
  text-align: center;
}

/* Icon Style */
.icon-container .upload-icon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
}

/* Text Style */
.icon-container span {
  color: var(--White, #FFF);
  font-family: Tajawal, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  text-align: center;
}

/* Hidden file input */
#file-input {
  display: none;
}



.icon-container {
  text-align: center;
  color: white;
}

.icon-container .upload-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}

.icon-container span {
  font-size: 12px;
  font-weight: bold;
}

#file-input {
  display: none;
}

  /* Header Line */
  .header-line {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    top: calc(240px + 0px);
    left: 0;
    z-index: 1;
  }
  
  /* Personal Info Container */
/* Personal Info Container */
.personal-info-container {
    position: absolute;
    top: 410px;
    right: 70px; /* Reduced this value to move it leftwards */
    left: 60%; /* Allow it to stretch from the left side */
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    padding: 16px;
    height: 370px;
    flex-shrink: 0;
    color: var(--Changing-Variables-Text-White);
    background-color: transparent;
    font-family: Tajwal, sans-serif;
  }
  
  
  /* Section Title with Border */
  .section-title {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-weight: 700;
    border-radius: 8px;
    border: 0.5px solid var(--Changing-Variables-Stroke-Button); /* Dynamic border */
    background-color: transparent;
    color: var(--Changing-Variables-Text-White);
  }
  
  /* Info Content with Border */

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
 
  /* Labels */
 /* Labels */
 .info-label {

  color: var(--Changing-Variables-Text-White, #FFF);
  text-align: right;
  
  /* Body List/Body 20 B */
  font-family: "Noto Sans Arabic";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
    text-align: right; /* Align labels to the right */
   
    direction: rtl; /* Right-to-left for Arabic text */
  }
  
  /* Values */
  .info-value {
    font-size: 20px;
    font-weight: 400;
    color: var(--Changing-Variables-Text-White);
    text-align: left; /* Align values to the left */
    direction: ltr; /* Left-to-right for English text */
  }
  
  
  /* Edit Icon */
  .edit-icon {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
  }

  /* Achievement Container */
.achievement-container {
    position: absolute;
    top: 790px; /* Position this under personal-info-container */
    right: 70px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    padding: 16px;
    height: 150px; /* Adjust this based on your needs */
    flex-shrink: 0;
    color: var(--Changing-Variables-Text-White);
    background-color: transparent;
    font-family: Tajwal, sans-serif;
  }
  
  /* Section Title for Achievement */
  .section-title {
    display: flex;
    font-family: Tajawal;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-weight: 700;
    border-radius: 8px;
    border: 0.5px solid var(--Changing-Variables-Stroke-Button); /* Dynamic border */
    background-color: transparent;
    color: var(--Changing-Variables-Text-White);
  }
  
  /* Info Content for Achievement */
  .info-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    border: 0.5px solid var(--Changing-Variables-Stroke-Button); /* Dynamic border */
    padding: 16px;
    border-radius: 8px;
  }
  
  /* Each Info Row for Achievement */
  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    
    flex-direction: row; /* Ensure correct row direction */
  }
  
a
  .info-text {
    color: var(--Changing-Variables-Text-White, #FFF);
text-align: right;

/* Others - Tajwal/Paragraph 18 R */
font-family: Tajawal;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
  }




  /* Vertical Line */
/* Vertical Line */
.vertical-line {
    position: absolute;
    top: calc(240px + 170px); /* Starts after 70px from the profile picture, assuming profile picture's height is 200px */
    left: 50%; /* Align it in the center horizontally */
    transform: translateX(-50%); /* Center it exactly */
    width: 1px; /* Thin line */
    height: calc(100vh - 20px); /* Stretch it from the profile picture to the bottom of the page */
    background-color: var(--Changing-Variables-Stroke-Button); /* Dynamic border color */
    z-index: 2; /* Ensure it stays above content */
  }


/* Header Section */
.settings-header {
    width: 100%;
    height: 240px;
    background-color: var(--Changing-Variables-Stroke-White); /* Dynamic background */
    overflow: hidden;
    position: relative;
    transform: translateY(-15px);
}

/* Header Image */
.header-background img {
    width: 100%;
    height: calc(100% + 15px);
    object-fit: cover;
    transform: translateY(15px);
}

/* Logo and Text inside Header */
.header-logo-container {
    position: absolute;
    left: 170px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

/* Logo Styling */
.header-logo {
    height: 80px;
}

/* Header Text */
.header-text {
    color: var(--Changing-Variables-Text-White);
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-left: 10px;
}

/* Profile Picture - Half Inside Header, Half Outside */

/* Profile Picture */


/* Vertical Divider Line */
/* Vertical Line - Stops at the Horizontal Line */
.vertical-line {
  position: absolute;
  top: calc(240px + 170px); /* Starts below the profile picture */
  left: 50%; /* Centers the line */
  transform: translateX(-50%);
  width: 1px;
  height: calc(410px + 250px + 160px + 200px - (240px + 170px)); /* Stops at the horizontal line */
  background-color: var(--Changing-Variables-Stroke-Button); /* Dynamic border color */
  z-index: 2;
}


/* Personal Info Container */
.personal-info-container {
    position: absolute;
    top: 410px;
    right: 70px;
    left: 60%; /* Allow it to stretch from the left side */
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    padding: 16px;
    height: 370px;
    flex-shrink: 0;
    color: var(--Changing-Variables-Text-White);
    background-color: transparent;
    font-family: Tajwal, sans-serif;
}

/* Section Title with Border */
.section-title {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-weight: 700;
    border-radius: 8px;
    border: 0.5px solid var(--Changing-Variables-Stroke-Button); /* Dynamic border */
    background-color: transparent;
    color: var(--Changing-Variables-Text-White);
}

/* Bio Container */
.bio-container {
    position: absolute;
    top: 410px;
    left: 70px; /* Align bio container to the left of the page */
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    padding: 16px;
    height: 370px;
    flex-shrink: 0;
    color: var(--Changing-Variables-Text-White);
    background-color: transparent;
    font-family: Tajwal, sans-serif;
}

/* Achievement Container */
.achievement-container {
    position: absolute;
    top: 790px;
    right: 70px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    padding: 16px;
    height: 150px;
    flex-shrink: 0;
    color: var(--Changing-Variables-Text-White);
    background-color: transparent;
    font-family: Tajwal, sans-serif;
}

/* Achievement Text */
.achievement-container .info-text {
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: right;
    font-family: Tajawal;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%; /* 27px */
}

/* Bio Text */
.bio-text {
    color: var(--Changing-Variables-Text-White, #FFF);
    text-align: right;
    font-family: Tajawal;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
}

/* ============================= */
/* Bio Container */
/* ============================= */
/* Bio Container */


/* Save Button */
.save-button {
  margin-top: 10px;
  background-color: #4a1b4f;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #762a69;
}

.edit-icon {
  cursor: pointer;
}

.edit-icon img {
  width: 20px;
  height: 20px;
}


.bio-container {
  position: absolute;
  top: 410px;
  right: calc(50% + 70px); /* Moves it 70px away from the center split line */
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  padding: 16px;
  height: auto; /* Allows height to expand dynamically */
  flex-shrink: 0;
  color: var(--Changing-Variables-Text-White);
  background-color: transparent;
  font-family: Tajwal, sans-serif;
}


/* Bio Content Box */
/* Bio Content Box */

.bio-textarea {
  width: 100%; /* Ensures text fills the box */
  color: var(--Changing-Variables-Text-White, #FFF);
  text-align: right;
  font-family: Tajawal;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%; /* Makes text readable */
  
  /* ✅ Fixes Overflowing Issue */
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 0.5px solid var(--Changing-Variables-Stroke-Button);
  background-color: transparent;
  padding: 16px;
  
  /* Fixed Width, but Height Expands */

  min-height: 100px;
  min-width: 400px;
  /* Ensure Dynamic Height */
  height: auto;
  width: auto;
  /* Prevent Overflowing */
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  transition: height 0.2s ease-in-out;
  
 
}
.bio-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 0.5px solid var(--Changing-Variables-Stroke-Button);
  background-color: transparent;
  padding: 16px;
  
  /* Fixed Width, but Height Expands */

  min-height: 100px;
  min-width: 200px;
  /* Ensure Dynamic Height */
  height: auto;
  
  /* Prevent Overflowing */
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  transition: height 0.2s ease-in-out;
}

/* Bio Text */
.bio-text {
  width: 100%; /* Ensures text fills the box */
  color: var(--Changing-Variables-Text-White, #FFF);
  text-align: right;
  font-family: Tajawal;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%; /* Makes text readable */
  
  /* ✅ Fixes Overflowing Issue */
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

/* Word Counter */
.word-count {
  width: 100%;
  text-align: left; /* Aligns to the bottom left */
  color: var(--Changing-Variables-Placeholders-and-Faded-Text, #B3B3B3);
  font-family: Tajawal;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  position: relative; /* Ensures it stays in place */
}

/* Accounts Content Box */
/* Other Accounts Container */
.other-accounts-container {
  position: absolute;
  top: calc(410px + 270px); /* Places it 90px under the Bio Section */
  right: calc(50% + 70px); /* Keeps it aligned with the right section */
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  padding: 16px;
  height: auto;
  flex-shrink: 0;
  color: var(--Changing-Variables-Text-White);
  background-color: transparent;
  font-family: Tajwal, sans-serif;
  width: 783px; /* ✅ Increases the width */
}

/* Accounts Content Box */
.accounts-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 0.5px solid var(--Changing-Variables-Stroke-Button);
  background-color: transparent;
  padding: 16px;
  min-height: 60px;
  height: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  width: 100%; /* ✅ Makes sure content fits inside the container */
}
/* Account Row */
.account-row {
  display: flex;
  align-items: center; /* ✅ Centers everything vertically */
  justify-content: space-between; /* ✅ Ensures spacing between label, value, and icon */
  width: 100%;
  padding: 10px 0;
  flex-direction: row-reverse; /* ✅ Arabic Alignment */
  gap: 20px; /* ✅ Adds space between elements */
}

/* Account Icon */
.account-icon {
  width: 28px;
  height: 28px;
  flex-shrink: 0; /* ✅ Prevents the icon from shrinking */
}

/* Account Label */
.account-label {
  font-size: 18px;
  font-weight: 700;
  color: var(--Changing-Variables-Text-White);
  text-align: right;
  min-width: 100px; /* ✅ Ensures the label always has a fixed width */
}

/* Account Value */
.account-value {
  font-size: 18px;
  font-weight: 400;
  color: var(--Changing-Variables-Text-White);
  text-align: center; /* ✅ Centers the value horizontally */
  flex-grow: 1; /* ✅ Ensures the value takes space properly */
  display: flex;
  justify-content: center; /* ✅ Centers value text */
  align-items: center; /* ✅ Centers value text vertically */
  padding: 0 20px; /* ✅ Adds padding to prevent it from getting too close to label */
}


/* Horizontal Line */
.horizontal-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--Changing-Variables-Stroke-Button);
  left: 0;
  z-index: 2;
  
  top: calc(410px + 250px + 160px + 200px); /* Adjusting its position below Other Accounts */
}
.horizontal-line-2 {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--Changing-Variables-Stroke-Button);
  left: 0;
  z-index: 2;
  
  top: calc(410px + 510px + 160px + 20px); /* Adjusting its position below Other Accounts */
}


/* Privacy Container - Move Closer to Horizontal Line & Add Side Spacing */
.privacy-container {
  display: flex;
  justify-content: space-between; /* Ensures password is on the left, toggles on the right */
  align-items: center;
  gap: 32px;
  width: calc(100% - 160px); /* Leaves 80px margin on both sides */
  padding: 10px 0; /* Reduced padding to bring it closer */
  position: absolute;
  top: calc(410px + 250px + 160px + 200px + 10px); /* Moves it closer to the horizontal line */
  left: 80px; /* Keep margin from the left */
  right: 80px; /* Keep margin from the right */
}

/* Change Password Button - Aligns to the Left */
.change-password {
  font-family: tajawal;
  display: flex;
  width: 40%; /* ✅ Set width to 40% of the parent container */
  max-width: 651px; /* ✅ Prevents it from getting larger than 651px */
  min-width: 250px; /* ✅ Prevents it from becoming too small on mobile */
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Changing-Variables-Buttons, #FFF);
  color: var(--Changing-Variables-Button-Text, #4A1B4F);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  align-self: flex-start;

  /* ✅ Smooth Transition */
  transition: all 0.3s ease-in-out;
}

/* ✅ Hover Effect */
.change-password:hover {
  background: var(--Changing-Variables-Text-White); /* Change background */
  color: var(--Changing-Variables-Button-Text); /* Change text color */
  transform: scale(1.05); /* Slightly increase size */
}


/* Privacy Options - Keeps them aligned to the right */
.privacy-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
}

/* Privacy Option */
.privacy-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

/* Checkbox */
.checkbox {
  width: 32px;
  height: 32px;
  border: 2px solid var(--Changing-Variables-Stroke-Button);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox:not(.checked):hover::after {
  content: "";
  background-image: url("../assets/images/checkmark.png"); /* Update path if needed */
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  opacity: 0.5; /* Faded effect */
  position: absolute;
}
/* Privacy Label */

.privacy-label {
  font-family: "Noto Sans Arabic";
  font-size: 20px;
  font-weight: 700;
  color: var(--Changing-Variables-Text-White);
}


/* Change Password Button */
/* Change Password Button - Move to Left */

/* Key Icon */
.password-icon {
  width: 24px;
  height: 24px;
}


/* Responsive Fix */
@media (max-width: 768px) {
  .other-accounts-container {
      width: 90%; /* Adjusts width for smaller screens */
      right: auto;
      left: auto;
  }
}
