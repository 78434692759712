.custom-datepicker-calendar {
    background-color: var(--Changing-Variables-Stroke-White);
    color: var(--Changing-Variables-Text-White);
    border: 1px solid var(--Changing-Variables-Stroke-border);
    border-radius: 12px;
    font-family: inherit;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    z-index: 9999;
    direction: ltr; /* ✅ Fixes the LTR layout */
  }
  
  .custom-datepicker-calendar .react-datepicker__header {
    background-color: transparent; /* ✅ No solid background */
    color: white;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    padding: 8px;
    margin: 0;
    font-size: 14px;
    text-align: center;
  }
  
  .custom-datepicker-calendar .react-datepicker__current-month,
  .custom-datepicker-calendar .react-datepicker__day-name {
    color: var(--Changing-Variables-Text-White);
  }
  
  .custom-datepicker-day {
    color: var(--Changing-Variables-Text-White);
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    transition: background-color 0.2s ease;
  }
  
  .custom-datepicker-day:hover {
    background-color: var(--Changing-Variables-Stroke-border);
    color: var(--Changing-Variables-Button-Text);
  }
  
  .custom-datepicker-calendar .react-datepicker__day--selected {
    background-color: var(--Changing-Variables-Buttons);
    color: var(--Changing-Variables-Button-Text);
  }
  
  /* ✅ Dropdown Selects */
  .custom-datepicker-calendar select {
    background-color: var(--Changing-Variables-line-White);
    color: black !important;
    border: 1px solid var(--Changing-Variables-Stroke-border);
    border-radius: 4px;
    padding: 4px 6px;
    margin: 0 4px;
    font-size: 13px;
  }
  
  .custom-datepicker-calendar .react-datepicker__triangle {
    display: none;
  }
  /* Hide days from previous and next months */
.custom-datepicker-calendar .react-datepicker__day--outside-month {
    visibility: hidden;
  }
  