/* Modal Overlay */
.cp-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .cp-modal-content {
    display: flex;
    width: 400px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    border-radius: 8px;
    background: var(--Primary, #4A1B4F);
  }
  
  /* Close Button */
  .cp-close-button {
    position: relative;
    top: 10px;
    right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }
  
  /* Text */
  .cp-password-text {
    color: #FFF;
    text-align: right;
    font-family: "Noto Sans Arabic";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .cp-password-question {
    color: #FFF;
    text-align: right;
    font-family: Tajawal, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  /* Input Fields */
  .cp-input-field {
    display: flex;
    width: 100%;
    padding: 10px;
    background: var(--Faded-Bars, rgba(179, 179, 179, 0.5));
    border-radius: 8px;
    gap: 10px;
    align-items: center;
    color: #B3B3B3;
  }
  
  .cp-input-field input {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    background: var(--Faded-Bars, white); /* Match the background with the input field */
    color:black;
    font-family: Tajawal, sans-serif;
    font-size: 14px;
    font-weight: 500;

    line-height: 21px;
  }
  
  /* Save Button */
  .cp-save-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 152px;
    padding: 10px;
    gap: 10px;
    border-radius: 8px;
    background: #FFF;
    color: #4A1B4F;
    font-family: Tajawal, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
  }
  
  .cp-save-button:hover {
    background: #f2f2f2;
  }
  