@import '../fonts.css';

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

.articles-section {
  padding: 40px;
  background-color: var(--Changing-Variables-Stroke-White);
  color: var(--Changing-Variables-Text-White);
  text-align: center;
  position: relative; /* Enables pseudo-element placement */
}


.articles-title {
  font-size: 36px;
  margin-bottom: 10px;
}

.articles-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--Changing-Variables-Placeholders-and-Faded-Text);
}

/* Categories Section */
.articles-categories {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
  direction: rtl;
}

.category-button {
  border-radius: 6px;
  border: 1px solid transparent;
  background: none;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Changing-Variables-Buttons);
  font-family: Tajawal;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: all 0.3s ease;
  cursor: pointer;
}

.category-button:hover {
  color: var(--Changing-Variables-Button-Text);
  background-color: var(--Changing-Variables-Buttons);
}

.category-button.active {
  color: var(--Changing-Variables-Button-Text);
  background-color: var(--Changing-Variables-Buttons); /* Active button background */
}

/* Selected Category Name */
.selected-category {
  margin-top: 20px;
  text-align: right; /* Align text to the right */
}

.selected-category-text {
  margin-bottom: 40px;
  margin-right: 176px;
  color: var(--Changing-Variables-Text-White, #FFF);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Articles Grid */
.articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px); /* Fixed card width */
  justify-content: center;
  gap: 20px;
}

.article-card {
  width: 300px; /* Fixed width for card */
  height: 450px;
  border-radius: 8px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.8) 66.27%
    ),
    var(--Changing-Variables-Faded-Bars);
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Content remains at the bottom */
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.article-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Content Section */
.article-content {
  z-index: 1;
  padding: 20px;
  position: relative;
  text-align: center; /* Center text alignment */
  display: flex;
  flex-direction: column;
  gap: 8px; /* Space between content */
  margin-left: auto;
  margin-right: auto; /* Center content horizontally */
  align-items: center; /* Center content within its container */
}

/* Tag Styles */
.article-tag-date {
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between; /* Space between the tag and date */
  width: 100%; /* Ensure it spans the full content width */
}

.article-tag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 16px;
  font-family: Poppins;
  font-size: 8px;
  font-weight: 700;
  text-align: center;
}

.article-tag.latest {
  color: #4A1B4F; /* White text */
  background: #fff; /* Dark purple background */
  width: 92px;
  padding: 0px 24px;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  text-align: center;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.article-tag.breaking {
  color: black; /* Black text */
  background: #F9F871; /* Yellow background */
  width: 92px;
  padding: 0px 24px;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  text-align: center;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.article-tag.exclusive {
  color: #fff; /* White text */
  background: #4A1B4F; /* Red background */
  width: 92px;
  padding: 0px 24px;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  text-align: center;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Title and Description */
.article-title {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 700;
  color: #fff;
  line-height: 1.4;
}

.article-description {
  align-self: stretch;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 400;
  color: #ccc;
  margin: 0;
  direction: rtl;
}

/* Stats Section */
.article-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-radius: 16px;
  margin-top: auto;
  width: 200px;
}

.article-likes,
.article-comments {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #b3b3b3;
}

.article-likes img,
.article-comments img {
  width: 15px;
  height: 15px;
}
@media (max-width: 768px) {
  .articles-section {
    padding: 20px;
  }

  .articles-categories {
    flex-wrap: wrap; /* Allow wrapping for categories */
    gap: 8px;
  }

  .articles-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust columns */
    gap: 15px;
  }

  .article-card {
    width: 100%; /* Full width */
    height: auto; /* Flexible height */
  }

  .article-content {
    padding: 10px; /* Adjust padding */
  }

  .article-title {
    font-size: 18px; /* Scale down */
  }

  .article-description {
    font-size: 14px;
  }
}

