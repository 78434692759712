

/* 🌟 Main Content Area */


/* 🌟 Navigation Bar */
.dashboard-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 32px;
  border-bottom: 1px solid var(--Changing-Variables-Stroke-border);
  background: var(--Changing-Variables-Stroke-White);
  z-index: 1000;
}



/* 🌟 Navigation Item */
.dashboard-nav-item {
  cursor: pointer;
  font-size: 14px;
  font-family: Tajawal;
  color: var(--Changing-Variables-Text-White);
  padding: 4px 8px;
}

/* 🌟 Active Navigation Item */
.dashboard-nav-item.active {
  background: var(--Changing-Variables-Buttons);
  color: var(--Changing-Variables-Button-Text);
  border-radius: 8px;
  font-weight: bold;
}
