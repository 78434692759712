
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');

.tajawal-extralight {
  font-family: "Tajawal", serif;
  font-weight: 200;
  font-style: normal;
}

.tajawal-light {
  font-family: "Tajawal", serif;
  font-weight: 300;
  font-style: normal;
}

.tajawal-regular {
  font-family: "Tajawal", serif;
  font-weight: 400;
  font-style: normal;
}

.tajawal-medium {
  font-family: "Tajawal", serif;
  font-weight: 500;
  font-style: normal;
}

.tajawal-bold {
  font-family: "Tajawal", serif;
  font-weight: 700;
  font-style: normal;
}

.tajawal-extrabold {
  font-family: "Tajawal", serif;
  font-weight: 800;
  font-style: normal;
}
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  font-size: 1.2rem;
  color: #555;
}

.tajawal-black {
  font-family: "Tajawal", serif;
  font-weight: 900;
  font-style: normal;
}
/* Ensure the body takes the full height of the page */
/* Default scroll behavior */
html, body {
 
  margin: 0;
  padding: 0;

}
/* ✅ Fix TinyMCE Popups and Layout Issues */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents unwanted horizontal scrolling */
  height: auto; /* Allows page to grow dynamically */
}

/* 🔹 Ensures TinyMCE popups stay inside */
.tox-tinymce-aux {
  position: absolute !important;
  z-index: 1001 !important; /* Keeps it above other elements */
}

/* 🏆 Fix editor container to prevent popups from going out */
#editor-toolbar-container {
  position: relative;
  z-index: 1002;
}

body.allow-scroll {
  overflow-y: auto; /* Allow scrolling */
}

body.no-scroll {
  overflow-y: hidden; /* Disable scrolling for Settings page */
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}

