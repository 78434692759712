/* SignUp Page Styling */
.sign-up-wrapper {
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #121212;
}

.sign-up-container {
  display: flex;
  flex-direction: row; /* Keep elements in a row */
  justify-content: flex-end; /* Push the sign-up form to the right */
  width: 100%; /* Full width */
  max-width: 1024px;
  height: 100%;
  position: absolute; /* Positioning the form to the right */
  right: 0;
}

/* LEFT SIDE - Harry Potter Image */
.sign-up-left {
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
  flex-shrink: 0;
  width: 75%; /* Take up half of the screen */
  height: 100vh;
}

.sign-up-character-img {
  width: 286px;
  height: 584px;
  flex-shrink: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}

/* RIGHT SIDE - Signup Form */
.sign-up-right {
  width: 50%;
  height: auto;
  background-color: #4a1b4f;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  position: relative;
}

h4 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

p {
  color: #fff;
  font-family: Tajawal, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 20px;
}

.sign-up-logo {
  display: none;
}

.signup-box {
  width: 100%;
  max-width: 512px;
}

.input-field {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.input-field::placeholder {
  color: #ccc;
}

.primary-button-signup {
  background-color: #4fd9fc;
  color: #4a1b4f;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-button-signup:hover {
  background-color: #3cb9d3;
}

.terms {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.terms label {
  margin-left: 10px;
  font-size: 14px;
  color: #fff;
}

.login-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
}

.login-now {
  color: #4fd9fc;
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
}

.login-now:hover {
  text-decoration: underline;
}

/* Positioning the Logo in Bottom Left */
.imgn-logo {
  position: absolute;
  bottom: 40px;
  left: 64px;
  width: 128px;
  height: 38px;
}

/* Styled Date Picker */
.input-field_date {
  width: 100%; /* Fix width issue */
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.1);
  color: #ccc;
}

/* Remove Duplicate Styling */
.input-field_date::placeholder {
  color: white;
}

/* Style the DatePicker input field */
.react-datepicker__input-container input {
  width: 200%;
  padding: 15px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  text-align: left;
  cursor: pointer;
}

/* Change placeholder text color */
.react-datepicker__input-container input::placeholder {
  color: #ccc;
}

/* Remove default outline and add glow effect */
.react-datepicker__input-container input:focus {
  outline: none;
  border: 2px solid #4fd9fc;
  box-shadow: 0 0 5px rgba(79, 217, 252, 0.8);
}

/* Style the calendar dropdown */
.react-datepicker {
  background-color: #4a1b4f;
  border-radius: 8px;
  border: 1px solid #4a1b4f;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Style the calendar header */
.react-datepicker__header {
  background-color: #4fd9fc;
  border-bottom: 1px solid #4a1b4f;
  color: #4a1b4f;
  font-weight: bold;
}

/* Style the calendar days */
.react-datepicker__day {
  color: #4a1b4f;
  font-size: 14px;
  padding: 8px;
  transition: 0.3s;
}

/* Highlight selected day */
.react-datepicker__day--selected {
  background-color: #4fd9fc !important;
  color: #4a1b4f !important;
  border-radius: 50%;
}

/* Style hove#4a1b4f days */
.react-datepicker__day:hover {
  background-color: rgba(79, 217, 252, 0.6);
  color: #4a1b4f;
  border-radius: 50%;
}

/* Change navigation arrow colors */
.react-datepicker__navigation {
  color: #4a1b4f;
  top: 10px;
}

.react-datepicker__navigation-icon::before {
  border-color: #4a1b4f !important;
}

/* Style the year dropdown */
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: #4a1b4f;
  color: #4a1b4f;
  border-radius: 5px;
  padding: 5px;
}

/* Style active year/month selection */
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected {
  background-color: #4fd9fc !important;
  color: #4a1b4f !important;
}

/* Ensure Year Navigation Arrows Are #4a1b4f */
.react-datepicker__navigation--years {
  top: 10px;
}

.react-datepicker__navigation--years .react-datepicker__navigation-icon::before {
  border-color: #4a1b4f !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sign-up-container {
    flex-direction: column;
    align-items: center;
  }

  .sign-up-left {
    display: none; /* Hide character on small screens */
  }

  .sign-up-right {
    width: 100%;
    max-width: 400px;
  }

  .input-field,
  .primary-button-signup {
    width: 100%;
    max-width: 100%;
  }
}
