/* ✅ Wrapper & Typography */
.articles-history-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #fff;
    font-family: "Tajawal", sans-serif;
  }
  
  .history-title {
    text-align: right;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
  
  /* 🔍 Search */
  .search-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    text-align: right;
  }
  
  .search-bar-cms {
    font-family: Tajawal;
    direction: ltr;
    display: flex;
    padding: 10.5px 16px;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--Changing-Variables-Stroke-border);
    background: var(--Changing-Variables-Faded-Bars);
    color: var(--Changing-Variables-Icons);
    text-align: center;
    font-size: 12px;
    font-weight: 800;
    line-height: 150%;
  }
  
  .search-bar-cms::placeholder {
    color: var(--Changing-Variables-Icons);
    opacity: 1;
  }
  
  .search-info {
    color: var(--Changing-Variables-Text-White);
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    margin-top: 5px;
    width: 100%;
  }
  
  /* 🧱 Grid Layout */
  .articles-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  
  .article-item {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    align-items: center;
    width: 100%;
  }
  
  /* 🔳 Info Box */
  .article-info-box {
    background: var(--Changing-Variables-Faded-Bars);
    border: 1px solid var(--Changing-Variables-Stroke-border);
    border-radius: 8px;
    padding: 10.5px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    color: var(--Changing-Variables-Placeholders-and-Faded-Text);
    text-align: center;
    direction: ltr;
    unicode-bidi: plaintext;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  /* 🧩 Actions */
  .article-actions {
    display:flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: var(--Changing-Variables-Icons);
    transition: 0.3s ease-in-out;
  }
  
  .icon-button.view {
    color: #0f9d58;
  }
  
  .icon-button.edit {
    color: #f4b400;
  }
  
  .icon-button.approve {
    color: #2196f3;
  }
  
  .icon-button.decline {
    color: #f44336;
  }
  
  .icon-button.delete {
    background-color: #d9534f;
    color: white;
    padding: 5px 8px;
    border-radius: 4px;
  }
  
  .icon-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .icon-button:hover {
    opacity: 0.8;
  }
  
  /* 🖼️ SVG Icon Styling */
  .cms-icon {
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--Changing-Variables-Icons);
    transition: fill 0.3s ease-in-out;
  }
  
  body:not(.dark-mode) .cms-icon {
    fill: #4A1B4F;
  }
  
  .icon-button:hover .cms-icon {
    fill: var(--Changing-Variables-Button-Text);
  }
  
  /* 🚫 No Data */
  .no-data {
    text-align: center;
    color: var(--Changing-Variables-Placeholders-and-Faded-Text);
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
  }
  
  .text-info {
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: var(--Changing-Variables-Text-White);
    margin-top: 16px;
  }
  
  /* 📱 Responsive */
  @media screen and (max-width: 768px) {
    .article-item {
      grid-template-columns: 1fr;
      gap: 8px;
    }
  
    .article-info-box,
    .article-actions {
      width: 100%;
      justify-content: center;
    }
  
    .icon-button {
      font-size: 1rem;
    }
  }
  