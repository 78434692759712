.dashboard-navbar {
  width: 100%;
  height: 64px;
  background: var(--Changing-Variables-Stroke-White);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid var(--Changing-Variables-Stroke-border);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  direction: ltr;
}

/* Toggle Button */
.navbar-toggle {
  display: none;
  background: var(--Changing-Variables-Buttons);
  color: var(--Changing-Variables-Button-Text);
  border: none;
  font-size: 24px;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

/* Profile Info */
.dashboard-profile-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dashboard-profile-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.dashboard-profile-info {
  display: flex;
  flex-direction: column;
  font-family: Tajawal;
  text-align: left;
}

.dashboard-profile-name {
  font-weight: bold;
}

.dashboard-profile-role {
  font-size: 12px;
  color: gray;
}

/* 🔔 Bell */
.notification-bell {
  cursor: pointer;
  font-size: 20px;
  position: relative;
}

.notification-bell-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* 🔽 Notification Dropdown */
.notification-center {
  position: absolute;
  top: 70px;
  right: 24px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1100;
}

.notification-center-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
}

.notification-list {
  padding: 12px;
}

.notification-item {
  margin-bottom: 12px;
  font-size: 14px;
  direction: rtl;
}

.notification-type {
  font-weight: bold;
}

.notification-time {
  font-size: 12px;
  color: gray;
}

/* Navigation Links */
.dashboard-nav-links {
  display: flex;
  gap: 20px;
}

.dashboard-nav-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 64px;
  left: 16px;
  background: var(--Changing-Variables-Stroke-White);
  padding: 16px;
  border-radius: 8px;
  z-index: 999;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
}

.dashboard-nav-item {
  cursor: pointer;
  font-family: Tajawal;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 14px;
  color: var(--Changing-Variables-Text-White);
  position: relative;
}

.dashboard-nav-item:hover {
  background-color: var(--Changing-Variables-Faded-Bars);
}

.dashboard-nav-item.active {
  background-color: var(--Changing-Variables-Buttons);
  color: var(--Changing-Variables-Button-Text);
}

.notification-badge {
  background: red;
  color: white;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 12px;
  margin-right: 6px;
}

/* Responsive */
@media (max-width: 1235px) {
  .navbar-toggle {
    display: block;
  }

  .dashboard-profile-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 12px;
    margin: 0 auto;
  }

  .dashboard-profile-info {
    text-align: center;
    align-items: center;
  }

  .notification-bell {
    margin-left: 8px;
  }

  .dashboard-nav-links {
    display: none !important;
  }

  .dashboard-nav-links.open {
    display: flex !important;
    flex-direction: column-reverse;
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    padding: 16px;
    background: var(--Changing-Variables-Stroke-White);
    border-top: 1px solid #ddd;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    direction: rtl;
    z-index: 999;
    animation: slideDown 0.3s ease-in-out;
  }

  .notification-center {
    left: auto;
    right: 16px;
  }
}


.dashboard-nav-links.open {
  animation: slideDown 0.3s ease-in-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
