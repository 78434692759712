@import '../fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Figtree:ital,wght@0,300..900;1,300..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+Arabic:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
:root {
  --Changing-Variables-Stroke-White: #121212;
  --Changing-Variables-line-White: #ffffff;
  --Changing-Variables-Stroke-border: #ffffff;
  --Changing-Variables-Stroke-Button: #ffffff;
  --Changing-Variables-Buttons: #FFFFFF;
  --Changing-Variables-Button-Text: #4a1b4f;
  --Changing-Variables-Faded-Bars: rgba(50, 50, 50, .5);
  --Changing-Variables-Placeholders-and-Faded-Text: #b3b3b3;
  --Changing-Variables-Icons: #ffffff;
  --Changing-Variables-Text-White: #ffffff;
  --Changing-Variables-side-menu: #ffffff;
  --Changing-Variables-Icons: #ffffff;
  --table:rgba(50, 50, 50, .5);

}

/* Light Mode Overrides */
body:not(.dark-mode) {
  --Changing-Variables-Stroke-White: #ffffff;
  --Changing-Variables-line-White: #4a1b4f;
  --Changing-Variables-Stroke-border: #4a1b4f;
  --Changing-Variables-Stroke-Button: #121212;
  --Changing-Variables-Buttons: #4a1b4f;
  --Changing-Variables-Button-Text: #ffffff;
  --Changing-Variables-Faded-Bars: rgba(179, 179, 179, 0.5);
  --Changing-Variables-Placeholders-and-Faded-Text: #777;
  --Changing-Variables-Icons: #121212;
  --Changing-Variables-Text-White: #4a1b4f;
  --Changing-Variables-side-menu: #4a1b4f;
  --Changing-Variables-Icons: #4A1B4F;
  --table:rgba(65, 119, 133, 0.788);
}

/* Global Body Styling */
body {
  margin: 0;
  font-family: Poppins, sans-serif;
  background-color: var(--Changing-Variables-Stroke-White);
  color: var(--Changing-Variables-Text-White);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Header Styles */
header {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 27px 176px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: var(--Changing-Variables-Stroke-White);
  border-bottom: 1px solid var(--Changing-Variables-Stroke-Button);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Logo Styling */
.logo img {
  width: 76.95px;
  height: 22.821px;
}

/* Search Bar Styles */
.search-bar {
  display: flex;
  margin-right: 30%;
  padding: 4px 160px 4px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: var(--Changing-Variables-Faded-Bars);
  color: var(--Changing-Variables-Placeholders-and-Faded-Text);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search-bar a {
  width: 16px;
  height: 16px;
  fill: var(--Changing-Variables-Icons);
}

/* Navigation Menu */



nav ul li {
  color: var(--Changing-Variables-Button-Text);
  list-style: none;
  font-family: Tajawal;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
nav ul li .signin-button:hover {
  background-color: var(--Changing-Variables-Button-Text);
  color: var(--Changing-Variables-Buttons);
}

nav ul li .signin-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  background-color: var(--Changing-Variables-Buttons);
  border: 1px solid var(--Changing-Variables-Stroke-Button);
  border-radius: 10px;
  text-decoration: none;
  font-family: Tajawal, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: var(--Changing-Variables-Button-Text);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}



/* Dark/Light Mode Toggle Styles */
.colormode {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Changing-Variables-Text-White);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.colorbutton {
  
  display: flex;
  width: 80px;
  height: 24px;
  padding: 2px;
  align-items: center;
  border-radius: 24px;
  background: var(--Changing-Variables-Faded-Bars);
  cursor: pointer;
  position: relative;
  overflow: hidden; /* Ensures icons stay inside the button */
}

/* Icons Inside the Button */
.colorbutton img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth movement */
  opacity: 1; /* Default to visible */
}

.colorbutton img.moon-icon {
  left: 10%; /* Start on the left */
}

.colorbutton img.sun-icon {
  right: 10%; /* Start on the right */
  opacity: 0; /* Hidden initially */
}

/* Animate to Light Mode */
body:not(.dark-mode) .colorbutton img.moon-icon {
  transform: translateY(-50%) translateX(-100%); /* Slide moon out to the left */
  opacity: 0; /* Fade out moon */
}

body:not(.dark-mode) .colorbutton img.sun-icon {
  transform: translateY(-50%) translateX(0); /* Slide sun in from the right */
  opacity: 1; /* Fade in sun */
}

/* Animate to Dark Mode */
body.dark-mode .colorbutton img.moon-icon {
  transform: translateY(-50%) translateX(0); /* Slide moon back into view */
  opacity: 1; /* Fade in moon */
}

body.dark-mode .colorbutton img.sun-icon {
  transform: translateY(-50%) translateX(100%); /* Slide sun out to the right */
  opacity: 0; /* Fade out sun */
}

/* Smooth Transitions for Header and Body */
header,
nav ul,
.colorbutton {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}


@media (max-width: 768px) {
  header {
    padding: 15px 20px; /* Reduce padding for smaller screens */
    height: auto; /* Allow flexible height */
    flex-wrap: wrap; /* Allow items to stack */
    gap: 10px; /* Add spacing between elements */
  }

  .logo img {
    width: 50px;
    height: auto;
  }

  .search-bar {
    flex: 1; /* Take full width */
    padding: 4px 8px;
    margin: 0; /* Remove margin for better alignment */
  }

  nav ul {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    gap: 10px;
  }

  nav ul li .signin-button {
    padding: 6px 16px; /* Reduce padding for smaller buttons */
  }
}
