.grid-row {
  display: flex;
  gap: 30px;
  margin-bottom: 15px;
  width: 100%; /* Full width */
}

.grid-row.col-1 {
  flex-direction: column;
}

.grid-row.col-2 {
  flex-direction: row;
}